@font-face {
  font-family: 'FuturaPT-Book';
  src: url('../assets/fonts/FuturaPT-Book.eot');
  src: url('../assets/fonts/FuturaPT-Book.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/FuturaPT-Book.woff2') format('woff2'),
    url('../assets/fonts/FuturaPT-Book.woff') format('woff'),
    url('../assets/fonts/FuturaPT-Book.ttf') format('truetype');
}

@font-face {
  font-family: 'FuturaPT-Bold';
  src: url('../assets/fonts/FuturaPT-Bold.eot');
  src: url('../assets/fonts/FuturaPT-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/FuturaPT-Bold.woff2') format('woff2'),
    url('../assets/fonts/FuturaPT-Bold.woff') format('woff'),
    url('../assets/fonts/FuturaPT-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'FuturaPT-BookOblique';
  src: url('../assets/fonts/FuturaPT-BookObl.eot');
  src: url('../assets/fonts/FuturaPT-BookObl.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/FuturaPT-BookObl.woff2') format('woff2'),
    url('../assets/fonts/FuturaPT-BookObl.woff') format('woff'),
    url('../assets/fonts/FuturaPT-BookObl.ttf') format('truetype');
}

@font-face {
  font-family: 'TalkeIcons';
  src: url('../assets/fonts/talke-icons.eot');
  src: url('../assets/fonts/talke-icons.woff') format('woff'),
    url('../assets/fonts/talke-icons.ttf') format('truetype');
}
