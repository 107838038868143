@teaserHeroWidth: 80%;

.block.imagesGrid,
.block.teaserGrid,
.block.grid {
  .ui.grid {
    margin-top: 0;
    margin-bottom: 0;
  }

  .column {
    z-index: 5;
  }
}

// This has to be backported to Volto core

// Block Modal
.ui.modal.block {
  top: initial;
  left: initial;
  margin-right: auto;
  margin-left: auto;

  .header {
    display: flex;
    justify-content: space-between;

    .icon {
      cursor: pointer;
    }
  }

  .content {
    .wrapper {
      border-bottom: 1px solid #edf1f2;

      label {
        font-weight: @bold;
        text-transform: uppercase;
      }
    }

    .ui.grid {
      margin: 0;
    }

    .field {
      margin-bottom: 10px;
    }

    .field-group-wrapper {
      border-bottom: 1px solid #c7d5d8;
    }
  }
}

.block .block.detached:hover::after {
  border: none;
}

.block .block.detached:not(.inner)::after {
  display: none;
}

// .block.image.align.center img {
//   display: block;
//   width: 100%; // This has to be backported
//   max-width: 100%;
//   height: auto;
// }

.block .block-tooltip {
  position: absolute;
  z-index: 5;
  top: -22px;
  display: none;
  padding: 2px 7px;
  background-color: #252525;
  border-radius: 2px;
  color: #f2f5f6;
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
}

.block.selected .block-tooltip,
.block:hover .block-tooltip {
  display: block;
}

// Adjustments to the segments in the sidebar
.ui.accordion .ui.segment {
  top: 0;
  bottom: 0;
  width: auto;
  border: none;
  border-top: none;
  border-bottom: 1px solid #b8c6c8;
  margin: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

// teasers view/edit
/* This might be required to standardize */
// .block.__grid.two .grid-image-wrapper {
//   height: 230px;
// }

.teaser-item,
.grid-teaser-item,
.grid-image-item {
  display: flex;
  flex-direction: column;

  .grid-image-wrapper {
    display: flex;
    // This one is because Safari has a bug with flexed items, the default is
    // align-items `stretched`, so it's required to reset it.
    align-items: flex-start;
  }

  img {
    width: 100%;
    object-fit: cover;
    object-position: top left;
  }

  a {
    color: @textColor;
  }

  p {
    // Defaults are always standard, on theme with customizations
    margin-bottom: 0;
    text-align: left;
  }

  &.default {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      max-width: 300px;
      align-self: center;
    }
  }

  a {
    display: flex;
  }

  &.top .grid-image-wrapper {
    margin-bottom: 25px;
  }

  &.top a {
    display: flex;
    flex-direction: column;
  }

  &.left a {
    flex-direction: row;

    .grid-image-wrapper {
      width: 49%;
      flex: 1 0 auto;
      @media only screen and (max-width: @largestMobileScreen) {
        width: 100%;
      }
    }

    h3,
    p {
      padding-left: 20px;
      @media only screen and (max-width: @largestMobileScreen) {
        padding-left: 0;
      }
    }

    @media only screen and (max-width: @largestMobileScreen) {
      flex-direction: column;

      img {
        width: 100%;
        margin-bottom: 25px;
      }
    }
  }

  &.right a {
    flex-direction: row-reverse;
    justify-content: space-between;

    .grid-image-wrapper {
      width: 49%;
      flex: 1 0 auto;
    }

    h3,
    p {
      padding-right: 20px;
    }

    @media only screen and (max-width: @largestMobileScreen) {
      flex-direction: column;

      img {
        width: 100%;
        margin-bottom: 25px;
      }
    }
  }

  &.padded {
    width: @teaserHeroWidth;
    margin: 0 auto;

    @media only screen and (max-width: @tabletBreakpoint) {
      width: 100%;
    }
  }
}

// teaserHero edit/view fix for variations
.block.inner.teaserHero .teaser-item {
  &.left {
    flex-direction: row;

    .grid-image-wrapper {
      width: 49%;
      flex: 1 0 auto;
      margin-right: 20px;
    }
  }

  &.right {
    flex-direction: row-reverse;
    justify-content: space-between;

    .grid-image-wrapper {
      width: 49%;
      flex: 1 0 auto;
      margin-left: 20px;
    }
  }
}

.accordion-tools {
  display: flex;
  align-items: center;

  .ui.basic.button {
    margin-right: 10px;

    &:hover {
      background-color: transparent !important;
    }
  }
}

.sidebar-listing-data {
  &.ui.form.segment {
    padding-bottom: 20px;
  }

  .simple-field-name {
    padding-top: 20px;
    padding-bottom: 10px;
    font-weight: 500;
  }

  .fields {
    flex-wrap: wrap;
    padding-bottom: 20px;
    border-bottom: 1px dotted @borderColor;
  }

  .fields:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  .main-fields-wrapper {
    display: flex;
    width: 100%;
    flex: 1 0 auto;
    padding-right: 0.5em;
    padding-left: 0.5em;
  }

  .button .icon {
    margin: 0 !important;
  }
}

.sidebar-teaser-data {
  .data-items-listing {
    justify-content: space-between;
  }
}

.ui.button.template-chooser-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 0 20px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);

  .template-chooser-title {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }
}

.blocks-chooser .ui.basic.button {
  text-align: center;
}

.block:not(.text) {
  clear: both;
}

// End of This has to be backported to Volto core

.data-items-listing {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  margin: 10px;
  background-color: #eee;
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;

  .icon {
    flex: 1 0 auto;
    margin-left: 10px !important;
  }
}
