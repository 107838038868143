@charset 'UTF-8';
/* stylelint-disable */
/*******************************
            Theme
*******************************/
/*******************************
        Theme Selection
*******************************/
/* To override a theme for an individual element specify theme name below */
/* Global */
/* Elements */
/* Collections */
/* Modules */
/* Views */
/* Extras */
/*******************************
            Folders
*******************************/
/* Path to theme packages */
/* Path to site override folder */
/*******************************
         Import Theme
*******************************/
/*******************************
        Import Directives
*******************************/
/*------------------
       Theme
-------------------*/
/*--------------------
   Site Variables
---------------------*/
/* Default site.variables */
/*******************************
         Site Settings
*******************************/
/*-------------------
       Fonts
--------------------*/
/*-------------------
      Base Sizes
--------------------*/
/* This is the single variable that controls them all */
/* The size of page text  */
/*-------------------
    Border Radius
--------------------*/
/* See Power-user section below
   for explanation of @px variables
*/
/*-------------------
    Brand Colors
--------------------*/
/*--------------
  Page Heading
---------------*/
/*--------------
   Form Input
---------------*/
/* This adjusts the default form input across all elements */
/* Input Text Color */
/* Line Height Default For Inputs in Browser (Descenders are 17px at 14px base em) */
/*-------------------
    Focused Input
--------------------*/
/* Used on inputs, textarea etc */
/* Used on dropdowns, other larger blocks */
/*-------------------
        Sizes
--------------------*/
/*
  Sizes are all expressed in terms of 14px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/
/*-------------------
        Page
--------------------*/
/*-------------------
      Paragraph
--------------------*/
/*-------------------
       Links
--------------------*/
/*-------------------
    Scroll Bars
--------------------*/
/* Inverted */
/*-------------------
  Highlighted Text
--------------------*/
/*-------------------
       Loader
--------------------*/
/*-------------------
        Grid
--------------------*/
/*-------------------
     Transitions
--------------------*/
/*-------------------
     Breakpoints
--------------------*/
/*-------------------
      Site Colors
--------------------*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Headers ---*/
/*--- Colored Text ---*/
/*--- Colored Border ---*/
/*-------------------
     Alpha Colors
--------------------*/
/*-------------------
       Accents
--------------------*/
/* Differentiating Neutrals */
/* Differentiating Layers */
/*******************************
           Power-User
*******************************/
/*-------------------
    Emotive Colors
--------------------*/
/* Positive */
/* Negative */
/* Info */
/* Warning */
/*-------------------
        Paths
--------------------*/
/* For source only. Modified in gulp for dist */
/*-------------------
       Em Sizes
--------------------*/
/*
  This rounds @size values to the closest pixel then expresses that value in (r)em.
  This ensures all size values round to exact pixels
*/
/* em */
/* rem */
/*-------------------
       Icons
--------------------*/
/* Maximum Glyph Width of Icon */
/*-------------------
     Neutral Text
--------------------*/
/*-------------------
     Brand Colors
--------------------*/
/*-------------------
      Borders
--------------------*/
/*-------------------
    Derived Values
--------------------*/
/* Loaders Position Offset */
/* Rendered Scrollbar Width */
/* Maximum Single Character Glyph Width, aka Capital "W" */
/* Used to match floats with text */
/* Header Spacing */
/* Minimum Mobile Width */
/* Positive / Negative Dupes */
/* Responsive */
/*-------------------
  Exact Pixel Values
--------------------*/
/*
  These are used to specify exact pixel values in em
  for things like borders that remain constantly
  sized as emSize adjusts

  Since there are many more sizes than names for sizes,
  these are named by their original pixel values.

*/
/* Columns */
/*******************************
             States
*******************************/
/*-------------------
      Disabled
--------------------*/
/*-------------------
        Hover
--------------------*/
/*---  Shadows  ---*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Focus
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
    Down (:active)
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Active
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/* Packaged site.variables */
/*******************************
         Site Settings
*******************************/
/*-------------------
       Fonts
--------------------*/
/*-------------------
      Base Sizes
--------------------*/
/*-------------------
    Border Radius
--------------------*/
/*-------------------
    Brand Colors
--------------------*/
/*--------------
  Page Heading
---------------*/
/*--------------
   Form Input
---------------*/
/* This adjusts the default form input across all elements */
/* Input Text Color */
/*-------------------
    Focused Input
--------------------*/
/*-------------------
        Sizes
--------------------*/
/*
  Sizes are all expressed in terms of 14px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/
/*-------------------
        Page
--------------------*/
/*-------------------
      Paragraph
--------------------*/
/*-------------------
       Links
--------------------*/
/*-------------------
    Scroll Bars
--------------------*/
/*-------------------
  Highlighted Text
--------------------*/
/*-------------------
       Loader
--------------------*/
/*-------------------
        Grid
--------------------*/
/*-------------------
     Transitions
--------------------*/
/*-------------------
     Breakpoints
--------------------*/
/*-------------------
      Site Colors
--------------------*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Headers ---*/
/*--- Colored Text ---*/
/*--- Colored Border ---*/
/*-------------------
     Alpha Colors
--------------------*/
/*-------------------
       Accents
--------------------*/
/*******************************
           Power-User
*******************************/
/*-------------------
    Emotive Colors
--------------------*/
/* Positive */
/* Negative */
/* Info */
/* Warning */
/*-------------------
        Paths
--------------------*/
/*-------------------
       Em Sizes
--------------------*/
/* em */
/* rem */
/*-------------------
       Icons
--------------------*/
/*-------------------
     Neutral Text
--------------------*/
/*-------------------
     Brand Colors
--------------------*/
/*-------------------
      Borders
--------------------*/
/*-------------------
    Derived Values
--------------------*/
/* Loaders Position Offset */
/* Rendered Scrollbar Width */
/* Maximum Single Character Glyph Width, aka Capital "W" */
/* Used to match floats with text */
/* Header Spacing */
/* Minimum Mobile Width */
/* Positive / Negative Dupes */
/* Responsive */
/*-------------------
  Exact Pixel Values
--------------------*/
/*
  These are used to specify exact pixel values in em
  for things like borders that remain constantly
  sized as emSize adjusts

  Since there are many more sizes than names for sizes,
  these are named by their original pixel values.

*/
/* Columns */
/*******************************
             States
*******************************/
/*-------------------
      Disabled
--------------------*/
/*-------------------
        Hover
--------------------*/
/*---  Shadows  ---*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Focus
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
    Down (:active)
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Active
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/* Component's site.variables */
/*******************************
         Site Settings
*******************************/
/*-------------------
       Fonts
--------------------*/
/*-------------------
      Base Sizes
--------------------*/
/*-------------------
    Border Radius
--------------------*/
/*-------------------
    Brand Colors
--------------------*/
/*--------------
  Page Heading
---------------*/
/*--------------
   Form Input
---------------*/
/* This adjusts the default form input across all elements */
/* Input Text Color */
/*-------------------
    Focused Input
--------------------*/
/*-------------------
        Sizes
--------------------*/
/*
  Sizes are all expressed in terms of 14px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/
/*-------------------
        Page
--------------------*/
/*-------------------
      Paragraph
--------------------*/
/*-------------------
       Links
--------------------*/
/*-------------------
    Scroll Bars
--------------------*/
/*-------------------
  Highlighted Text
--------------------*/
/*-------------------
       Loader
--------------------*/
/*-------------------
        Grid
--------------------*/
/*-------------------
     Transitions
--------------------*/
/*-------------------
     Breakpoints
--------------------*/
/*-------------------
      Site Colors
--------------------*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Headers ---*/
/*--- Colored Text ---*/
/*--- Colored Border ---*/
/*-------------------
     Alpha Colors
--------------------*/
/*-------------------
       Accents
--------------------*/
/*******************************
           Power-User
*******************************/
/*-------------------
    Emotive Colors
--------------------*/
/* Positive */
/* Negative */
/* Info */
/* Warning */
/*-------------------
        Paths
--------------------*/
/*-------------------
       Em Sizes
--------------------*/
/* em */
/* rem */
/*-------------------
       Icons
--------------------*/
/*-------------------
     Neutral Text
--------------------*/
/*-------------------
     Brand Colors
--------------------*/
/*-------------------
      Borders
--------------------*/
/*-------------------
    Derived Values
--------------------*/
/* Loaders Position Offset */
/* Rendered Scrollbar Width */
/* Maximum Single Character Glyph Width, aka Capital "W" */
/* Used to match floats with text */
/* Header Spacing */
/* Minimum Mobile Width */
/* Positive / Negative Dupes */
/* Responsive */
/*-------------------
  Exact Pixel Values
--------------------*/
/*
  These are used to specify exact pixel values in em
  for things like borders that remain constantly
  sized as emSize adjusts

  Since there are many more sizes than names for sizes,
  these are named by their original pixel values.

*/
/* Columns */
/*******************************
             States
*******************************/
/*-------------------
      Disabled
--------------------*/
/*-------------------
        Hover
--------------------*/
/*---  Shadows  ---*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Focus
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
    Down (:active)
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Active
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/* Site theme site.variables */
/*-------------------
 Component Variables
---------------------*/
/* Default */
/* Packaged Theme */
/* Site Theme */
/*******************************
             Mix-ins
*******************************/
/*------------------
       Fonts
-------------------*/
/*------------------
     Overrides
-------------------*/
/* End Config */
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
@import '../../../../../../../theme/extras/~@fortawesome/fontawesome-pro/css/all.css';
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger.is-active:hover {
  opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #000;
}
.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}
.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -10px;
}
.hamburger-inner::after {
  bottom: -10px;
}
/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  perspective: 80px;
}
.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx .hamburger-inner::before,
.hamburger--3dx .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(180deg);
}
.hamburger--3dx.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
.hamburger--3dx.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}
/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  perspective: 80px;
}
.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx-r .hamburger-inner::before,
.hamburger--3dx-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(-180deg);
}
.hamburger--3dx-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
.hamburger--3dx-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}
/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  perspective: 80px;
}
.hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy .hamburger-inner::before,
.hamburger--3dy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(-180deg);
}
.hamburger--3dy.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
.hamburger--3dy.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}
/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  perspective: 80px;
}
.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy-r .hamburger-inner::before,
.hamburger--3dy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg);
}
.hamburger--3dy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
.hamburger--3dy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}
/*
   * 3DXY
   */
.hamburger--3dxy .hamburger-box {
  perspective: 80px;
}
.hamburger--3dxy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy .hamburger-inner::before,
.hamburger--3dxy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg);
}
.hamburger--3dxy.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
.hamburger--3dxy.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}
/*
   * 3DXY Reverse
   */
.hamburger--3dxy-r .hamburger-box {
  perspective: 80px;
}
.hamburger--3dxy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy-r .hamburger-inner::before,
.hamburger--3dxy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
}
.hamburger--3dxy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
.hamburger--3dxy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}
/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}
.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}
/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
}
.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}
/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
/*
   * Arrow Turn
   */
.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg);
}
.hamburger--arrowturn.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
}
.hamburger--arrowturn.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}
/*
   * Arrow Turn Right
   */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg);
}
.hamburger--arrowturn-r.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}
.hamburger--arrowturn-r.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}
/*
   * Boring
   */
.hamburger--boring .hamburger-inner,
.hamburger--boring .hamburger-inner::before,
.hamburger--boring .hamburger-inner::after {
  transition-property: none;
}
.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg);
}
.hamburger--boring.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}
.hamburger--boring.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}
/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}
.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}
/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse-r .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
.hamburger--collapse-r .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--collapse-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}
.hamburger--collapse-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}
/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic .hamburger-inner::before {
  top: 10px;
  transition: opacity 0.125s 0.275s ease;
}
.hamburger--elastic .hamburger-inner::after {
  top: 20px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(135deg);
  transition-delay: 0.075s;
}
.hamburger--elastic.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}
.hamburger--elastic.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-270deg);
  transition-delay: 0.075s;
}
/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic-r .hamburger-inner::before {
  top: 10px;
  transition: opacity 0.125s 0.275s ease;
}
.hamburger--elastic-r .hamburger-inner::after {
  top: 20px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-135deg);
  transition-delay: 0.075s;
}
.hamburger--elastic-r.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}
.hamburger--elastic-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(270deg);
  transition-delay: 0.075s;
}
/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden;
}
.hamburger--emphatic .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}
.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}
.hamburger--emphatic .hamburger-inner::after {
  top: 10px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}
.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}
.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -80px;
  top: -80px;
  transform: translate3d(80px, 80px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -80px;
  top: -80px;
  transform: translate3d(-80px, 80px, 0) rotate(-45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden;
}
.hamburger--emphatic-r .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}
.hamburger--emphatic-r .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}
.hamburger--emphatic-r .hamburger-inner::after {
  top: 10px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}
.hamburger--emphatic-r.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}
.hamburger--emphatic-r.is-active .hamburger-inner::before {
  left: -80px;
  top: 80px;
  transform: translate3d(80px, -80px, 0) rotate(-45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.hamburger--emphatic-r.is-active .hamburger-inner::after {
  right: -80px;
  top: 80px;
  transform: translate3d(-80px, -80px, 0) rotate(45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
/*
   * Minus
   */
.hamburger--minus .hamburger-inner::before,
.hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear;
}
.hamburger--minus.is-active .hamburger-inner::before,
.hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear;
}
.hamburger--minus.is-active .hamburger-inner::before {
  top: 0;
}
.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0;
}
/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 2px;
}
.hamburger--slider .hamburger-inner::before {
  top: 10px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}
.hamburger--slider .hamburger-inner::after {
  top: 20px;
}
.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
.hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}
.hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-90deg);
}
/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 2px;
}
.hamburger--slider-r .hamburger-inner::before {
  top: 10px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}
.hamburger--slider-r .hamburger-inner::after {
  top: 20px;
}
.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}
.hamburger--slider-r.is-active .hamburger-inner::before {
  transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
  opacity: 0;
}
.hamburger--slider-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(90deg);
}
/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin-r .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin-r .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s 0.13s linear;
}
.hamburger--spring .hamburger-inner::before {
  top: 10px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring .hamburger-inner::after {
  top: 20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important;
}
.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}
/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring-r .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear;
}
.hamburger--spring-r .hamburger-inner::before {
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spring-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear;
}
.hamburger--spring-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}
/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}
.hamburger--stand .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}
.hamburger--stand.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--stand.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}
.hamburger--stand-r .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand-r .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}
.hamburger--stand-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--stand-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}
.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex .hamburger-inner::before,
.hamburger--vortex .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}
.hamburger--vortex .hamburger-inner::before {
  transition-property: top, opacity;
}
.hamburger--vortex .hamburger-inner::after {
  transition-property: bottom, transform;
}
.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex.is-active .hamburger-inner::before,
.hamburger--vortex.is-active .hamburger-inner::after {
  transition-delay: 0s;
}
.hamburger--vortex.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}
.hamburger--vortex.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
}
/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex-r .hamburger-inner::before,
.hamburger--vortex-r .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}
.hamburger--vortex-r .hamburger-inner::before {
  transition-property: top, opacity;
}
.hamburger--vortex-r .hamburger-inner::after {
  transition-property: bottom, transform;
}
.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex-r.is-active .hamburger-inner::before,
.hamburger--vortex-r.is-active .hamburger-inner::after {
  transition-delay: 0s;
}
.hamburger--vortex-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}
.hamburger--vortex-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}
.Toastify__toast-container {
  z-index: 9999;
  -webkit-transform: translate3d(0, 0, 9999px);
  position: fixed;
  padding: 4px;
  width: 320px;
  box-sizing: border-box;
  color: #fff;
}
.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}
.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  margin-left: -160px;
}
.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}
.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}
.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  margin-left: -160px;
}
.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}
@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }
  .Toastify__toast-container--top-left,
  .Toastify__toast-container--top-center,
  .Toastify__toast-container--top-right {
    top: 0;
  }
  .Toastify__toast-container--bottom-left,
  .Toastify__toast-container--bottom-center,
  .Toastify__toast-container--bottom-right {
    bottom: 0;
  }
  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}
.Toastify__toast {
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 1px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr;
}
.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}
.Toastify__toast--info {
  background: #3498db;
}
.Toastify__toast--success {
  background: #07bc0c;
}
.Toastify__toast--warning {
  background: #f1c40f;
}
.Toastify__toast--error {
  background: #e74c3c;
}
.Toastify__toast-body {
  margin: auto 0;
  -ms-flex: 1;
  flex: 1;
}
@media only screen and (max-width: 480px) {
  .Toastify__toast {
    margin-bottom: 0;
  }
}
.Toastify__close-button {
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  -ms-flex-item-align: start;
  align-self: flex-start;
}
.Toastify__close-button--default {
  color: #000;
  opacity: 0.3;
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
  opacity: 1;
}
@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 9999;
  opacity: 0.7;
  background-color: rgba(255, 255, 255, 0.7);
  transform-origin: left;
}
.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
  transition: transform 0.2s;
}
.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: right;
}
.Toastify__progress-bar--default {
  background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
}
@keyframes Toastify__bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes Toastify__bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.Toastify__bounce-enter--top-left,
.Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}
.Toastify__bounce-enter--top-right,
.Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}
.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}
.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}
.Toastify__bounce-exit--top-left,
.Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}
.Toastify__bounce-exit--top-right,
.Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}
.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}
.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}
@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}
.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}
@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}
@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}
.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}
@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}
.Toastify__slide-enter--top-left,
.Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}
.Toastify__slide-enter--top-right,
.Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}
.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}
.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}
.Toastify__slide-exit--top-left,
.Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}
.Toastify__slide-exit--top-right,
.Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}
.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}
.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

body {
  display: flex;
}
@media only screen and (max-width: 767px) {
  body {
    flex-direction: column;
  }
}
@media only screen and (max-width: 767px) {
  body.has-toolbar-menu-open {
    overflow: hidden;
  }
}
#main {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.documentFirstHeading {
  position: relative;
  border-bottom: 2px solid #B8C6C8;
}
.documentFirstHeading::before {
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 30px;
  height: 0;
  border-bottom: 3px solid #E40166;
  content: '';
}
.documentDescription {
  color: #5d7a8e;
  font-size: 1.125rem;
}
::-moz-selection {
  border-width: 20px;
  border-style: solid;
  background-color: rgba(31, 189, 238, 0.5);
  border-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2MCIgaGVpZ2h0PSI2MCIgdmlld0JveD0iMCAwIDYwIDYwIj4KICA8ZyBmaWxsPSIjMUZCRUVGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIG9wYWNpdHk9Ii45NSI+CiAgICA8cGF0aCBkPSJNMTAgNjBDNC40NzcxNTI1IDYwIDAgNTUuNTI0MDYxOCAwIDUwIDMuNTUyNzEzNjhlLTE1IDQ0LjQ3NzE1MjUgNC40NzU5MzgxOCA0MCAxMCA0MEwyMCA0MCAyMCA1MEMyMCA1NS41MjI4NDc1IDE1LjUyNDA2MTggNjAgMTAgNjB6TTQwIDUwQzQwIDU1LjUyMjg0NzUgNDQuNDc1OTM4MiA2MCA1MCA2MEw1MCA2MEM1NS41MjI4NDc1IDYwIDYwIDU1LjUyNDA2MTggNjAgNTBMNjAgNTBDNjAgNDQuNDc3MTUyNSA1NS41MjQwNjE4IDQwIDUwIDQwTDQwIDQwIDQwIDUwIDQwIDUweiIvPgogIDwvZz4KPC9zdmc+Cg==') 20 fill repeat;
}
::selection {
  border-width: 20px;
  border-style: solid;
  background-color: rgba(31, 189, 238, 0.5);
  border-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2MCIgaGVpZ2h0PSI2MCIgdmlld0JveD0iMCAwIDYwIDYwIj4KICA8ZyBmaWxsPSIjMUZCRUVGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIG9wYWNpdHk9Ii45NSI+CiAgICA8cGF0aCBkPSJNMTAgNjBDNC40NzcxNTI1IDYwIDAgNTUuNTI0MDYxOCAwIDUwIDMuNTUyNzEzNjhlLTE1IDQ0LjQ3NzE1MjUgNC40NzU5MzgxOCA0MCAxMCA0MEwyMCA0MCAyMCA1MEMyMCA1NS41MjI4NDc1IDE1LjUyNDA2MTggNjAgMTAgNjB6TTQwIDUwQzQwIDU1LjUyMjg0NzUgNDQuNDc1OTM4MiA2MCA1MCA2MEw1MCA2MEM1NS41MjI4NDc1IDYwIDYwIDU1LjUyNDA2MTggNjAgNTBMNjAgNTBDNjAgNDQuNDc3MTUyNSA1NS41MjQwNjE4IDQwIDUwIDQwTDQwIDQwIDQwIDUwIDQwIDUweiIvPgogIDwvZz4KPC9zdmc+Cg==') 20 fill repeat;
}
svg.circled {
  box-sizing: content-box;
  padding: 4px;
  border: 2px solid currentColor;
  border-radius: 50px;
}
svg.icon {
  box-sizing: content-box;
}
button .close {
  color: #e40166;
}
.ui.form,
.ui.segments,
.ui.segment {
  position: static !important;
}
button.contents {
  color: #007eb1;
}
button.save {
  color: #007eb1;
}
button.cancel {
  color: #e40166;
}
button.edit {
  color: #007eb1;
}
.button-reset {
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  text-align: initial;
}
.button-reset:focus {
  outline: none;
}
.drag.handle {
  cursor: move;
}
.drag.layer {
  position: relative;
  padding: 1rem 2rem 1rem 2rem;
  margin: -1rem -2rem 0 -2rem;
}
.drag.layer:hover .drag.edit,
.drag.layer:hover .drag.toolbar {
  display: block;
}
.drag.layer .drag.handle {
  margin-bottom: 0.5rem;
}
.drag.toolbar {
  position: absolute;
  top: 12px;
  left: 0;
  display: none;
}
.drag.edit {
  position: absolute;
  top: 12px;
  right: 0;
  display: none;
}
.editbar {
  position: fixed;
  z-index: 10;
  top: 60px;
  left: 400px;
}
.deletion {
  background-color: #f8cbcb;
}
.addition {
  background-color: #a6f3a6;
}
.SocialMediaShareCount,
.SocialMediaShareButton {
  display: inline-block;
}
.comments {
  margin-top: 20px;
}
#page-addons .secondary {
  text-transform: uppercase;
}
#page-addons .secondary .circular.label {
  margin-left: 0.5em;
}
#page-addons .title {
  color: #262626;
  font-size: 0.77777778rem;
  text-transform: uppercase;
}
#page-addons .title.updateAvailable {
  color: #517776;
}
#page-addons .title .accordionToggle {
  float: right;
}
#page-addons .title .updateText {
  margin-left: 5px;
  font-size: 0.66666667rem;
  text-transform: none;
}
#page-addons .title .updateText ::before {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  background-color: #517776;
  border-radius: 50%;
  content: '';
}
#page-addons .title .icon.accordionToggle {
  color: #262626;
}
#page-addons .description {
  margin-bottom: 1em;
  color: #68778D;
}
#page-addons .installAction {
  float: right;
}
#page-addons .uninstallAction {
  float: right;
}
#page-addons .version {
  color: #517776;
  font-size: 0.66666667rem;
}
.Toastify__toast-container {
  width: 480px;
  color: inherit;
}
@media only screen and (max-width: 767px) {
  .Toastify__toast-container {
    width: 100vw;
  }
}
.Toastify__toast-container--bottom-center {
  bottom: 0;
  padding: 0;
  margin-left: -240px;
}
@media only screen and (max-width: 767px) {
  .Toastify__toast-container--bottom-center {
    margin-left: 0;
  }
}
.Toastify__toast-container--bottom-center .Toastify__toast {
  min-height: 60px;
  align-items: center;
  padding: 18px;
  margin-bottom: 0;
}
.Toastify__toast-container--bottom-center .Toastify__toast-body {
  display: flex;
}
.Toastify__toast-container--bottom-center .toast-inner-content {
  margin-left: 8px;
}
.Toastify__toast-container--bottom-center .toast-inner-content h4 {
  padding-top: 4px;
  margin-bottom: 0;
  font-weight: bold;
}
.Toastify__toast-container--bottom-center .toast-inner-content p {
  font-weight: 300;
}
.Toastify__toast-container--bottom-center .Toastify__toast--info {
  background: #aee2f2;
}
.Toastify__toast-container--bottom-center .Toastify__toast--error {
  background: #f5c1c1;
}
.Toastify__toast-container--bottom-center .Toastify__toast--success {
  background: #c9eab1;
}
.Toastify__toast-container--bottom-center .Toastify__toast--warning {
  background: #f3e2ab;
}
.users-control-panel .table {
  overflow-x: scroll;
}
.users-control-panel .table::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  /* scrollbar height */
  background: transparent;
  /* optional: just make scrollbar invisible */
}
.controlpanel .ui .grid h3 {
  flex-direction: column;
}
.has-toolbar .pusher {
  height: 100px;
  transition: height 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.has-toolbar-collapsed .pusher {
  height: 20px;
  transition: height 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
#toolbar .toolbar {
  position: fixed;
  z-index: 100;
  display: flex;
  width: 100%;
  height: 20px;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 #c7d5d8;
  transition: height 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
#toolbar .toolbar.expanded {
  height: 100px;
}
#toolbar .toolbar.expanded .toolbar-actions {
  opacity: 1;
  transition: opacity 0.1s 0.2s;
}
#toolbar .toolbar.expanded .toolbar-actions.hidden {
  opacity: 0;
  transition: opacity 0.1s 0.2s;
}
#toolbar .toolbar.expanded .toolbar-body {
  height: 100px;
}
#toolbar .toolbar.expanded .toolbar-bottom a {
  opacity: 1;
}
#toolbar .toolbar.expanded .toolbar-bottom .minipastanaga {
  bottom: 10px;
  opacity: 0;
  transition: bottom 0.3s 0.3s, opacity 0.3s;
}
#toolbar .toolbar.expanded .toolbar-button-spacer {
  margin-left: auto;
}
#toolbar .toolbar .toolbar-handler button {
  opacity: 0.3;
}
#toolbar .toolbar .toolbar-handler .published:before {
  background: #007eb1;
}
#toolbar .toolbar .toolbar-handler .private:before {
  background: #e40166;
}
#toolbar .toolbar-content {
  overflow: hidden;
  max-height: 0;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 #c7d5d8;
  opacity: 0;
  transition: max-height 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045), opacity 0.3s 0.3s;
}
#toolbar .toolbar-content.show {
  max-height: 900px;
  opacity: 1;
  transition: max-height 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045), opacity 0.3s 0.3s;
}
#toolbar .toolbar-body {
  display: flex;
  height: 0;
  flex-direction: row-reverse;
  transition: height 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
#toolbar .toolbar-body a,
#toolbar .toolbar-body button {
  margin-left: 20px;
  color: #826A6A;
  cursor: pointer;
}
#toolbar .toolbar-body button {
  padding: 0;
  border: 0;
  background: transparent;
  text-align: initial;
}
#toolbar .toolbar-body button:focus {
  outline: none;
}
#toolbar .toolbar-body button.ui {
  margin-right: 0;
}
#toolbar .toolbar-body button.ui svg.icon {
  margin: 0;
}
#toolbar .toolbar-actions {
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: row-reverse;
  align-items: center;
  padding: 0 20px;
  opacity: 0;
  transition: opacity 0.1s 0.2s;
}
#toolbar .toolbar-actions .contents {
  color: #007eb1;
}
#toolbar .toolbar-actions .contents.circled {
  border-color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0.05);
  color: #826a6a;
}
#toolbar .toolbar-actions .save {
  color: #007eb1;
}
#toolbar .toolbar-actions .cancel {
  color: #e40166;
}
#toolbar .toolbar-actions .edit {
  color: #007eb1;
}
#toolbar .toolbar-actions a,
#toolbar .toolbar-actions button {
  display: block;
}
#toolbar .toolbar-handler {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: center;
}
#toolbar .toolbar-handler button {
  width: 80px;
  height: 20px;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  transition: opacity 0.3s;
}
#toolbar .toolbar-handler button::before {
  position: relative;
  display: block;
  width: 100%;
  height: 4px;
  background-color: red;
  content: '';
}
#toolbar .toolbar-bottom {
  display: flex;
  align-items: center;
}
#toolbar .toolbar-bottom a {
  opacity: 0;
}
#toolbar .toolbar-bottom .user {
  display: none;
}
#toolbar .toolbar-bottom .divider {
  display: none;
}
#toolbar .toolbar-bottom .pastanagalogo {
  display: none;
}
#toolbar .toolbar-bottom .minipastanaga {
  position: absolute;
  bottom: 2px;
  height: 14px;
  padding: 0 6px;
  opacity: 1;
  transition: bottom 0.3s 0.3s, opacity 0.3s 0.3s;
}
#toolbar .toolbar-content button,
#toolbar .toolbar button {
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  text-align: initial;
}
#toolbar .toolbar-content button:focus,
#toolbar .toolbar button:focus {
  outline: none;
}
#toolbar .toolbar-content {
  z-index: 3;
  box-shadow: 0 1px 2px 0 #c7d5d8;
  overflow-y: auto;
}
#toolbar .pusher-puller {
  position: relative;
  z-index: 2;
  display: flex;
  width: 2000px;
  transition: transform 300ms linear;
  will-change: transform;
}
@media only screen and (min-width: 767px) {
  #toolbar .toolbar {
    width: 20px;
    height: 100%;
    flex-direction: row;
    justify-content: flex-end;
    transition: width 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  #toolbar .toolbar + .pusher {
    margin-right: 20px;
    transition: margin-right 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  #toolbar .toolbar.expanded {
    width: 80px;
    height: 100%;
  }
  #toolbar .toolbar.expanded + .pusher {
    margin-right: 80px;
    transition: margin-right 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  #toolbar .toolbar.expanded .toolbar-body {
    height: 100%;
  }
  #toolbar .toolbar.expanded .toolbar-actions {
    flex-direction: column;
  }
  #toolbar .toolbar.expanded .toolbar-bottom .user {
    opacity: 1;
    transition: opacity 0.1s 0.2s;
  }
  #toolbar .toolbar.expanded .toolbar-bottom .pastanagalogo {
    display: block;
  }
  #toolbar .toolbar.expanded .toolbar-bottom .divider {
    width: 40px;
    height: 20px;
    border-top: 1px solid #c7d5d8;
    opacity: 1;
    transition: opacity 0.1s 0.2s;
  }
  #toolbar .toolbar-content {
    position: fixed;
    left: 80px;
    width: 320px;
  }
  #toolbar .toolbar-body {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    transition: width 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  #toolbar .toolbar-body a,
  #toolbar .toolbar-body button {
    margin-bottom: 20px;
    margin-left: 0;
  }
  #toolbar .toolbar-actions {
    flex: 1;
    flex-direction: column;
    padding: 20px 0;
  }
  #toolbar .toolbar-actions .more {
    flex: 0;
  }
  #toolbar .toolbar-bottom {
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
  }
  #toolbar .toolbar-bottom .user {
    display: block;
    opacity: 0;
  }
  #toolbar .toolbar-bottom .divider {
    display: block;
    opacity: 0;
  }
  #toolbar .toolbar-bottom .minipastanaga {
    bottom: 10px;
  }
  #toolbar .toolbar-bottom .pastanagalogo {
    display: block;
    padding-right: 6px;
    padding-left: 6px;
    margin-bottom: 10px;
  }
  #toolbar .toolbar-bottom .pastanagalogo img {
    width: 100%;
  }
  #toolbar .toolbar-handler {
    display: flex;
    width: 20px;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }
  #toolbar .toolbar-handler button {
    width: 20px;
    height: 80px;
  }
  #toolbar .toolbar-handler button::before {
    left: 8px;
    width: 4px;
    height: 100%;
  }
  #toolbar .pusher-puller > * {
    width: 320px;
  }
}
#toolbar .pastanaga-menu {
  width: 100vw;
  height: calc(100vh - 100px);
}
#toolbar .pastanaga-menu.has-inner-actions {
  height: 100vh;
}
@media only screen and (min-width: 767px) {
  #toolbar .pastanaga-menu.has-inner-actions {
    height: auto;
  }
}
#toolbar .pastanaga-menu .ui.container {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
#toolbar .pastanaga-menu header {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  border-bottom: 4px solid #b8c6c8;
}
#toolbar .pastanaga-menu header h2 {
  flex: 1 0 auto;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}
#toolbar .pastanaga-menu header a {
  display: flex;
}
#toolbar .pastanaga-menu header.pulled {
  justify-content: flex-start;
  padding-right: 10px;
  padding-left: 10px;
}
#toolbar .pastanaga-menu header.pulled button {
  margin-right: 10px;
}
#toolbar .pastanaga-menu .ui.form {
  height: 100%;
}
#toolbar .pastanaga-menu .ui.raised.segments {
  display: flex;
  height: calc(100vh - 60px);
}
@media only screen and (min-width: 767px) {
  #toolbar .pastanaga-menu .ui.raised.segments {
    max-height: calc(520px - 60px);
  }
}
#toolbar .pastanaga-menu .ui.raised.segments .ui.segment:not(.actions) {
  flex: 1 1 auto;
  overflow-y: auto;
}
#toolbar .pastanaga-menu .ui.raised.segments .ui.clearing.segment.actions:after {
  content: initial;
}
@media only screen and (min-width: 767px) {
  #toolbar .pastanaga-menu {
    width: 320px;
    height: auto;
  }
}
#toolbar .pastanaga-menu-list ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px;
  margin-top: 0;
  list-style: none;
}
#toolbar .pastanaga-menu-list li a,
#toolbar .pastanaga-menu-list li button {
  display: flex;
  width: 100%;
  height: 45px;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  border: none;
  background-color: transparent;
  color: #252525;
  cursor: pointer;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  text-transform: uppercase;
}
#toolbar .pastanaga-menu-list li a svg,
#toolbar .pastanaga-menu-list li button svg {
  color: #007eb1;
}
#toolbar .pastanaga-menu-list li:not(.state-select):not(.display-select) {
  border-bottom: 1px solid #b8c6c8;
}
#toolbar .pastanaga-menu-list .pastanaga-menu-label {
  margin-right: 12px;
}
#toolbar .pastanaga-menu-list .pastanaga-menu-value {
  font-weight: 300;
  text-transform: initial;
}
#toolbar .personal-tools header .back,
#toolbar .personal-tools header .vertical.divider {
  margin-right: 10px;
}
@media only screen and (min-width: 767px) {
  #toolbar .personal-tools header .back,
  #toolbar .personal-tools header .vertical.divider {
    display: none;
  }
}
#toolbar .personal-tools header .icon.logout {
  color: #e40166;
}
#toolbar .personal-tools .avatar {
  height: 220px;
  background-color: #f2f5f6;
}
#toolbar .personal-tools .avatar img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: top;
}
#toolbar .personal-tools .avatar.default {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #826A6A;
}
#toolbar .personal-tools .stats ul {
  display: flex;
  justify-content: center;
  padding: 15px 0;
  margin: 0;
  background-color: #f2f5f6;
  color: #878f93;
  list-style: none;
}
#toolbar .personal-tools .stats li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}
#toolbar .personal-tools .stats li span:first-child {
  font-size: 30px;
  font-weight: 200;
}
#toolbar .personal-tools .stats li span:last-child {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}
#toolbar .vertical.divider {
  height: 30px;
  margin-right: 20px;
  border-left: 1px solid #c7d5d8;
}
#toolbar .profile header button,
#toolbar .personal-tools header button {
  color: #007eb1;
}
#toolbar .menu-more header svg {
  color: #d12c67;
}
@media only screen and (min-width: 767px) {
  #toolbar .menu-more .more-user {
    display: none;
  }
}
#toolbar .menu-more .state-select,
#toolbar .menu-more .display-select {
  display: flex;
  width: 100%;
}
#toolbar .menu-more .state-select .react-select-container,
#toolbar .menu-more .display-select .react-select-container {
  flex: 1 0 75%;
}
#toolbar .menu-more .state-select label,
#toolbar .menu-more .display-select label {
  flex: 1 0 25%;
  padding-top: 18px;
  margin-right: 5px;
  border-bottom: 1px solid #edf1f2;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}
.ui.dropdown .menu.left {
  right: 0;
  left: auto;
}
.ui.segment.dashed {
  border: 3px dashed #ccc;
  box-shadow: none;
}
@media only screen and (max-width: 767px) {
  .ui.menu.stackable > .menu,
  .ui.menu.stackable > .menu.right {
    display: block;
  }
}
.draftJsToolbar__buttonWrapper__1Dmqh {
  display: inline-block;
}
.draftJsToolbar__button__qi1gf {
  width: 36px;
  height: 34px;
  padding-top: 5px;
  border: 0;
  background: #fbfbfb;
  color: #888;
  font-size: 18px;
  vertical-align: bottom;
}
.draftJsToolbar__button__qi1gf svg {
  fill: #888;
}
.draftJsToolbar__button__qi1gf:hover,
.draftJsToolbar__button__qi1gf:focus {
  background: #f3f3f3;
  outline: 0;
  /* reset for :focus */
}
.draftJsToolbar__active__3qcpF {
  background: #efefef;
  color: #444;
}
.draftJsToolbar__active__3qcpF svg {
  fill: #444;
}
.draftJsToolbar__separator__3U7qt {
  display: inline-block;
  height: 24px;
  margin: 0 0.5em;
  border-right: 1px solid #ddd;
}
.draftJsToolbar__toolbar__dNtBH {
  position: absolute;
  z-index: 2;
  left: 50%;
  box-sizing: border-box;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 #dcdcdc;
  -webkit-transform: translate(-50%) scale(0);
  transform: translate(-50%) scale(0);
}
.draftJsToolbar__toolbar__dNtBH:after,
.draftJsToolbar__toolbar__dNtBH:before {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  border: solid transparent;
  content: ' ';
  pointer-events: none;
}
.draftJsToolbar__toolbar__dNtBH:after {
  margin-left: -4px;
  border-width: 4px;
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
}
.draftJsToolbar__toolbar__dNtBH:before {
  margin-left: -6px;
  border-width: 6px;
  border-color: rgba(221, 221, 221, 0);
  border-top-color: #ddd;
}
.draftJsToolbar__blockType__27Jwn {
  width: 36px;
  height: 36px;
  box-sizing: border-box;
  padding: 5px;
  margin: 0;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 18px;
  cursor: pointer;
  line-height: 36px;
  text-align: center;
}
.draftJsToolbar__blockType__27Jwn svg {
  fill: #888;
}
.draftJsToolbar__spacer__2Os2z {
  position: absolute;
  left: 50%;
  width: 74px;
  height: 8px;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}
.draftJsToolbar__popup__GHzbY {
  position: absolute;
  z-index: 3;
  left: 50%;
  width: 74px;
  box-sizing: border-box;
  margin-top: 8px;
  border: 1px solid #ddd;
  background: #efefef;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 #dcdcdc;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}
.draftJsToolbar__popup__GHzbY:after,
.draftJsToolbar__popup__GHzbY:before {
  position: absolute;
  bottom: 100%;
  left: 50%;
  width: 0;
  height: 0;
  border: solid transparent;
  content: ' ';
  pointer-events: none;
}
.draftJsToolbar__popup__GHzbY:after {
  margin-left: -4px;
  border-width: 4px;
  border-color: rgba(251, 251, 251, 0);
  border-bottom-color: #fbfbfb;
}
.draftJsToolbar__popup__GHzbY:before {
  margin-left: -6px;
  border-width: 6px;
  border-color: rgba(221, 221, 221, 0);
  border-bottom-color: #ddd;
}
.draftJsToolbar__buttonWrapper__1Dmqh {
  display: inline-block;
}
.draftJsToolbar__button__qi1gf {
  width: 36px;
  height: 34px;
  padding-top: 5px;
  border: 0;
  background: #fbfbfb;
  color: #888;
  font-size: 18px;
  vertical-align: bottom;
}
.draftJsToolbar__button__qi1gf svg {
  fill: #888;
}
.draftJsToolbar__button__qi1gf:hover,
.draftJsToolbar__button__qi1gf:focus {
  background: #f3f3f3;
  outline: 0;
  /* reset for :focus */
}
.draftJsToolbar__active__3qcpF {
  background: #efefef;
  color: #444;
}
.draftJsToolbar__active__3qcpF svg {
  fill: #444;
}
.draftJsToolbar__separator__3M3L7 {
  display: inline-block;
  height: 24px;
  margin: 0 0.5em;
  border-right: 1px solid #ddd;
}
.draftJsToolbar__wrapper__9NZgg {
  position: absolute;
  z-index: 1;
}
.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
  height: inherit;
  text-align: initial;
}
.public-DraftEditor-content[contenteditable='true'] {
  -webkit-user-modify: read-write-plaintext-only;
}
.public-DraftEditor-content[contenteditable='true'] {
  caret-color: #e40166;
}
.public-DraftEditor-content {
  min-height: 19px;
}
.DraftEditor-root {
  position: relative;
}
.DraftEditor-editorContainer {
  position: relative;
  z-index: 1;
}
.public-DraftEditor-block {
  position: relative;
}
.public-DraftEditorPlaceholder-root {
  position: absolute;
  z-index: 1;
  color: #B8C6C8;
}
.DraftEditorPlaceholder-hidden {
  display: none;
}
.draftJsToolbar__toolbar__dNtBH {
  position: absolute;
  z-index: 10;
  top: -32px;
  left: 50%;
  display: flex;
  padding: 4px;
  background-color: rgba(255, 255, 255, 0.975);
  border-radius: 2px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.05);
  transform: translate(-50%, 0);
  z-index: 102;
  padding: 3px;
  border: none;
}
.draftJsToolbar__toolbar__dNtBH form {
  display: flex;
}
.draftJsToolbar__toolbar__dNtBH > svg {
  display: inline-block !important;
  box-sizing: content-box;
  padding: 4px;
  margin-right: 4px;
  color: #826A6A !important;
}
.draftJsToolbar__toolbar__dNtBH .ui.input > input {
  padding: 0;
  border: none;
}
.draftJsToolbar__toolbar__dNtBH .ui.icon.button {
  padding: 4px;
  margin-left: 4px;
  border-radius: 1px;
  color: #826A6A !important;
}
.draftJsToolbar__toolbar__dNtBH .ui.icon.button:hover {
  background-color: #edf1f2 !important;
}
.draftJsToolbar__toolbar__dNtBH .ui.buttons:first-child .ui.icon.button {
  margin-left: 0;
}
.draftJsToolbar__toolbar__dNtBH:before {
  border: none;
}
.draftJsToolbar__button__qi1gf {
  width: 32px;
  height: 32px;
  padding: 0;
  padding: 4px !important;
  background: rgba(255, 255, 255, 0.975);
  border-radius: 1px;
  color: #826A6A;
}
.draftJsToolbar__buttonWrapper__1Dmqh + .draftJsToolbar__buttonWrapper__1Dmqh {
  margin-left: 3px;
}
.draftJsToolbar__button__qi1gf.draftJsToolbar__active__3qcpF {
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px #007EB1;
  color: #007EB1;
}
.draftJsToolbar__separator__3U7qt {
  height: 32px;
}
.block:focus {
  outline: none;
}
.block .block:not(.inner)::before {
  position: absolute;
  z-index: -1;
  top: -9px;
  left: -9px;
  width: calc(100% + 18px);
  height: calc(100% + 18px);
  border: 1px solid rgba(120, 192, 215, 0);
  border-radius: 3px;
  content: '';
}
.block .block-add-button {
  display: none;
}
.block.selected .block-add-button {
  display: inline-block;
}
.block .block.selected::before,
.block .block.selected:hover::before {
  border-width: 1px;
  border-color: rgba(120, 192, 215, 0.75);
}
.block .block:hover::before {
  border-color: rgba(120, 192, 215, 0.375);
}
.block.video,
.block.image {
  /* This fixes two left floated items in a row */
  clear: both;
}
.ui.drag.block:not(:last-child) {
  margin-bottom: 2rem;
}
.ui.drag.block.video,
.ui.drag.block.image {
  z-index: 2;
  display: block;
  clear: both;
}
.block .ui.image {
  width: 100%;
}
.block .ui.message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.block .ui.message .message-text {
  margin-top: 20px;
  color: #767676;
}
.block .ui.message span {
  margin-top: 20px;
}
.block.maps iframe {
  width: 100%;
  height: 45vh;
}
.block.video video {
  width: 100%;
}
.block.align.left {
  z-index: 2;
}
.block.align.left.video .video-inner,
.block.align.left.maps iframe,
.block.align.left img {
  margin-right: 1em !important;
  margin-bottom: 1em;
  float: left;
}
.block.align.left.video .video-inner {
  width: 50%;
}
.block.align.left.maps iframe {
  width: 50%;
  height: 45vh;
}
.block.align.left .ui.image,
.block.align.left img {
  max-width: 50%;
}
.block.align.right {
  z-index: 2;
}
.block.align.right.maps iframe,
.block.align.right.video .video-inner,
.block.align.right img {
  margin-bottom: 1em;
  margin-left: 1em !important;
  float: right;
}
.block.align.right.video .video-inner {
  width: 50%;
}
.block.align.right.maps iframe {
  width: 50%;
  height: 45vh;
}
.block.align.right .ui.image,
.block.align.right img {
  max-width: 50%;
}
.block.align.center iframe,
.block.align.center img {
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.block.align.center iframe {
  width: 100%;
}
body .ui.wrapper > .block.align.full,
body.has-toolbar.has-sidebar .ui.wrapper > .ui.inner .block.align.full,
body.has-toolbar-collapsed.has-sidebar .ui.wrapper > .ui.inner .block.align.full,
body.has-toolbar-collapsed.has-sidebar-collapsed .ui.wrapper > .ui.inner .block.align.full,
body.has-toolbar.has-sidebar-collapsed .ui.wrapper > .ui.inner .block.align.full {
  width: 100% !important;
}
body .ui.wrapper > .block.align.full iframe,
body.has-toolbar.has-sidebar .ui.wrapper > .ui.inner .block.align.full iframe,
body.has-toolbar-collapsed.has-sidebar .ui.wrapper > .ui.inner .block.align.full iframe,
body.has-toolbar-collapsed.has-sidebar-collapsed .ui.wrapper > .ui.inner .block.align.full iframe,
body.has-toolbar.has-sidebar-collapsed .ui.wrapper > .ui.inner .block.align.full iframe {
  width: 100%;
}
body .ui.wrapper > .block.align.full img,
body.has-toolbar.has-sidebar .ui.wrapper > .ui.inner .block.align.full img,
body.has-toolbar-collapsed.has-sidebar .ui.wrapper > .ui.inner .block.align.full img,
body.has-toolbar-collapsed.has-sidebar-collapsed .ui.wrapper > .ui.inner .block.align.full img,
body.has-toolbar.has-sidebar-collapsed .ui.wrapper > .ui.inner .block.align.full img {
  width: 100%;
}
body.has-toolbar.has-sidebar .ui.wrapper > .ui.inner.block.full,
body.has-toolbar-collapsed.has-sidebar .ui.wrapper > .ui.inner.block.full,
body.has-toolbar-collapsed.has-sidebar-collapsed .ui.wrapper > .ui.inner.block.full,
body.has-toolbar.has-sidebar-collapsed .ui.wrapper > .ui.inner.block.full {
  width: 100% !important;
}
body.has-toolbar.has-sidebar .ui.wrapper > .ui.inner.block.full iframe,
body.has-toolbar-collapsed.has-sidebar .ui.wrapper > .ui.inner.block.full iframe,
body.has-toolbar-collapsed.has-sidebar-collapsed .ui.wrapper > .ui.inner.block.full iframe,
body.has-toolbar.has-sidebar-collapsed .ui.wrapper > .ui.inner.block.full iframe {
  width: 100%;
}
body.has-toolbar.has-sidebar .ui.wrapper > .ui.inner.block.full img,
body.has-toolbar-collapsed.has-sidebar .ui.wrapper > .ui.inner.block.full img,
body.has-toolbar-collapsed.has-sidebar-collapsed .ui.wrapper > .ui.inner.block.full img,
body.has-toolbar.has-sidebar-collapsed .ui.wrapper > .ui.inner.block.full img {
  width: 100%;
}
.block.align:not(.right):not(.left) {
  clear: both;
}
.title.block .public-DraftEditorPlaceholder-inner {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.28571429em;
}
.ui.block.table {
  border: 0;
}
.block {
  position: relative;
  margin-bottom: 1em;
}
.block h1:last-child,
.block h2:last-child,
.block h3:last-child,
.block h4:last-child,
.block h5:last-child {
  margin: calc(2rem -  0.14285714em ) 0em 1rem;
}
.block p {
  margin: 0em 0em 1em;
}
.block .toolbar {
  position: absolute;
  z-index: 10;
  top: -32px;
  left: 50%;
  display: flex;
  padding: 4px;
  background-color: rgba(255, 255, 255, 0.975);
  border-radius: 2px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.05);
  transform: translate(-50%, 0);
}
.block .toolbar form {
  display: flex;
}
.block .toolbar > svg {
  display: inline-block !important;
  box-sizing: content-box;
  padding: 4px;
  margin-right: 4px;
  color: #826A6A !important;
}
.block .toolbar .ui.input > input {
  padding: 0;
  border: none;
}
.block .toolbar .ui.icon.button {
  padding: 4px;
  margin-left: 4px;
  border-radius: 1px;
  color: #826A6A !important;
}
.block .toolbar .ui.icon.button:hover {
  background-color: #edf1f2 !important;
}
.block .toolbar .ui.buttons:first-child .ui.icon.button {
  margin-left: 0;
}
.block .toolbar-inner {
  display: flex;
  width: 450px;
  height: 40px;
  padding: 4px;
  background-color: rgba(255, 255, 255, 0.975);
  border-radius: 2px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.05);
}
.block .toolbar-inner form {
  display: flex;
}
.block .toolbar-inner > svg {
  display: inline-block !important;
  box-sizing: content-box;
  padding: 4px;
  margin-right: 4px;
  color: #826A6A !important;
}
.block .toolbar-inner .ui.input {
  width: 100%;
  margin-left: 8px;
}
.block .toolbar-inner .ui.input > input {
  padding: 0;
  border: none;
}
.block .toolbar-inner .ui.icon.button {
  padding: 4px;
  margin-left: 4px;
  border-radius: 1px;
  color: #826A6A !important;
}
.block .toolbar-inner .ui.icon.button:hover {
  background-color: #edf1f2 !important;
}
.block .toolbar-inner .ui.buttons:first-child .ui.icon.button {
  margin-left: 0;
}
.block .ui.active.basic.icon.button {
  border: 1px solid #2996da;
  background: transparent !important;
}
.block.hero {
  clear: both;
}
.block.hero .toolbar {
  left: 25%;
}
.block.hero .hero-body {
  max-height: 400px;
  flex: 1 1;
  padding: 30px;
  background-color: #EDF1F2;
  overflow-y: hidden;
}
.block.hero .hero-body h1 {
  margin-top: 0;
}
.block.hero .hero-body p {
  max-height: 287px;
  margin: 0;
  overflow-y: hidden;
  word-break: break-word;
}
.block.hero .hero-image {
  display: block;
  width: auto;
  max-width: 50%;
  height: auto;
  max-height: 400px;
}
.block.hero .image-add {
  min-width: 50%;
}
.block.hero .image-message {
  min-height: 400px !important;
}
.block.hero .block-inner-wrapper {
  position: relative;
  z-index: 1;
  display: flex;
}
.block.hero .title-editor,
.block.hero .description-editor {
  max-height: 287px;
  overflow-y: hidden;
  word-break: break-word;
}
.ui.basic.button.block-add-button {
  position: absolute;
  top: -2px;
  padding: 0;
  margin-bottom: 0;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #b8c6c8 !important;
  transform: translateX(-40px);
}
.ui.basic.button.block-add-button:hover,
.ui.basic.button.block-add-button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #826A6A !important;
}
.block .ui.basic.button.delete-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.block .ui.basic.button.delete-button:hover,
.block .ui.basic.button.delete-button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #e40166 !important;
}
.drag.block.wrapper {
  position: relative;
}
.drag.handle.wrapper {
  position: absolute;
  z-index: 1;
  left: 0;
  color: #b8c6c8;
}
.drag.handle.wrapper .icon {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
}
.drag.handle.wrapper:hover {
  color: #826A6A;
}
.drag.block.title .drag.handle.wrapper {
  top: 5px;
}
.drag.handle.wrapper.hidden {
  display: none !important;
}
.description.block .public-DraftEditorPlaceholder-inner {
  font-size: 1.125rem;
  line-height: 1.4285em;
}
.callout {
  padding: 1em;
  margin: 1rem 0;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
}
.ui.blocker {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.separator {
  display: inline-block;
  height: 24px;
  margin: 0 0 4px 4px;
  border-right: 1px solid #ddd;
  vertical-align: bottom;
}
.html-editor {
  z-index: 1;
  background-color: #f3f6f7;
  border-radius: 4px;
  font-family: monospace;
  font-size: 0.88888889rem;
}
.html-editor:focus-within textarea {
  caret-color: #e40166;
  outline: 0;
}
.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #90a4ae;
}
.token.punctuation {
  color: #9e9e9e;
}
.namespace {
  opacity: 0.7;
}
.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #e91e63;
}
.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #4caf50;
}
.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #795548;
}
.token.atrule,
.token.attr-value,
.token.keyword {
  color: #3f51b5;
}
.token.function {
  color: #f44336;
}
.token.regex,
.token.important,
.token.variable {
  color: #ff9800;
}
.token.important,
.token.bold {
  font-weight: bold;
}
.token.italic {
  font-style: italic;
}
.token.entity {
  cursor: help;
}
.link-form-container button {
  padding: 0;
  border: 0;
  background: transparent;
  color: #007EB1;
  cursor: pointer;
  text-align: initial;
  text-decoration: underline;
}
.link-form-container button:focus {
  outline: none;
}
.blocks-chooser {
  position: absolute;
  z-index: 10;
  top: -12px;
  left: -9px;
  width: 310px;
  padding: 4px;
  background-color: rgba(255, 255, 255, 0.975);
  border-radius: 2px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.05);
}
.blocks-chooser .ui.basic.button {
  display: flex;
  width: 100px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 3px;
  color: #826A6A !important;
  text-align: center;
}
.blocks-chooser .ui.basic.button:hover {
  background: #edf2f3 !important;
}
.blocks-chooser .ui.basic.button .icon {
  margin-bottom: 10px !important;
}
.blocks-chooser .ui.basic.button.table {
  border: none;
}
.blocks-chooser .ui.accordion .active {
  display: flex;
}
.blocks-chooser .ui.accordion .title {
  display: flex;
  justify-content: space-between;
}
.blocks-chooser .ui.accordion .content {
  padding: 0;
}
.blocks-chooser .ui.accordion .title ~ .content,
.blocks-chooser .ui.accordion .accordion .title ~ .content {
  display: initial;
}
.accordion-tools {
  display: flex;
  align-items: center;
}
.accordion-tools .ui.basic.button {
  margin-right: 10px;
}
.accordion-tools .ui.basic.button:hover {
  background-color: transparent !important;
}
.listing .items-preview {
  color: #517776;
  text-align: center;
}
.listing.message {
  padding: 1.5em;
  background: #f9edbe;
  color: #613a00;
  text-align: center;
}
.listing-item,
.listing-item a {
  display: flex;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .listing-item,
  .listing-item a {
    flex-direction: column;
  }
}
.listing-item {
  margin-bottom: 20px;
}
.listing-item img {
  width: 15%;
  margin-right: 20px;
}
@media only screen and (max-width: 767px) {
  .listing-item img {
    width: 100%;
    margin: 0;
  }
}
.listing-item .image-placeholder {
  display: flex;
  width: 15%;
  justify-content: center;
  margin-right: 20px;
}
.listing-item .image-placeholder img {
  width: 100%;
  max-width: 100%;
}
.listing-item a p {
  color: rgba(0, 0, 0, 0.87);
}
@media only screen and (max-width: 767px) {
  .listing-item h3 {
    margin-top: 1em;
  }
}
.sidebar-listing-data.ui.form.segment,
.sidebar-listing-data.listing-style {
  padding-bottom: 20px;
}
.sidebar-listing-data .simple-field-name {
  padding-top: 20px;
  padding-bottom: 10px;
  font-weight: 500;
}
.sidebar-listing-data .fields {
  flex-wrap: wrap;
  padding-bottom: 20px;
  border-bottom: 1px dotted #C7D5D8;
}
.sidebar-listing-data .fields:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.sidebar-listing-data .main-fields-wrapper {
  display: flex;
  width: 100%;
  flex: 1 0 auto;
  padding-right: 0.5em;
  padding-left: 0.5em;
}
.sidebar-listing-data .button .icon {
  margin: 0 !important;
}
@media only screen and (max-width: 767px) {
  .has-toolbar .sidebar-container {
    top: 100px;
    bottom: 0;
    height: calc(100vh - 100px);
  }
  .has-toolbar-collapsed .sidebar-container {
    top: 20px;
    bottom: 0;
    height: calc(100vh - 20px);
  }
}
.ui.button.full-size-sidenav-btn {
  position: absolute;
  top: 0;
  padding: 12px 6px;
  background: transparent;
}
.full-size-icon {
  height: 36px !important;
  color: #bac5c7;
}
.sidebar-container {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  width: 375px;
  height: 100vh;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 #c7d5d8;
  transition: right 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045), height 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045), width 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045), top 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.sidebar-container.collapsed {
  right: -355px;
}
.sidebar-container.collapsed .tabs-wrapper {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.sidebar-container.full-size {
  width: calc(100% - 80px);
}
.sidebar-container.full-size.no-toolbar {
  width: calc(100% - 20px);
}
.sidebar-container .ui.raised.segments {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.sidebar-container button {
  padding: 0;
  border: 0;
  background: transparent;
  text-align: initial;
}
.sidebar-container button:focus {
  outline: none;
}
.sidebar-container .trigger,
.sidebar-container .trigger:focus,
.sidebar-container .trigger:hover {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0;
  width: 20px;
  height: 80px;
  padding: 0;
  margin-top: -40px;
  background: transparent;
  border-radius: 0;
  opacity: 0.05;
  transition: opacity 0.3s;
}
.sidebar-container .trigger:hover {
  opacity: 0.3;
  transition: opacity 0.3s;
}
.sidebar-container .trigger:before {
  position: relative;
  left: 8px;
  display: block;
  width: 4px;
  height: 100%;
  background: #007EB1;
  content: '';
}
.sidebar-container .tab-wrapper {
  height: calc(100% - 61px);
  padding: 0;
  border-bottom: none;
  overflow-x: hidden;
  overflow-y: auto;
}
.sidebar-container .tab-wrapper > * {
  display: none;
}
.sidebar-container .tab-wrapper > :last-child {
  display: block;
}
.sidebar-container .tabs-wrapper {
  height: 100%;
}
.sidebar-container .tabs-wrapper .formtabs.menu a:first-child {
  margin-left: 36px;
}
.sidebar-container .tab-forbidden {
  margin: calc(50% - 24px) auto;
  color: #bac5c7;
}
.sidebar-container header {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  border-bottom: 4px solid #b8c6c8;
}
.sidebar-container header .icon {
  padding-right: 10px;
}
.sidebar-container header h2 {
  flex: 1 0 auto;
  margin: 0 !important;
  color: #517776;
  font-size: 16px;
}
.sidebar-container header form {
  flex: 1 0 auto;
}
.sidebar-container header .search {
  width: 100%;
}
.sidebar-container header .search input {
  border: none;
}
.sidebar-container header a {
  display: flex;
}
.sidebar-container header.pulled {
  justify-content: flex-start;
  padding-right: 10px;
  padding-left: 10px;
}
.sidebar-container header.pulled button {
  margin-right: 10px;
  cursor: pointer;
}
.sidebar-container .ui.segment:not(.tab) {
  border-bottom: 1px solid #b8c6c8;
}
.sidebar-container .ui.segment.tabbed-actions {
  display: flex;
  justify-content: space-around;
  padding: 0;
  border-top: 1px solid #b8c6c8;
  border-bottom: 1px solid #b8c6c8;
}
.sidebar-container .ui.segment.tabbed-actions .ui.buttons {
  width: 100%;
}
.sidebar-container .ui.segment.tabbed-actions .ui.buttons .button:last-child {
  border-radius: 0;
}
.sidebar-container .ui.segment.tabbed-actions .ui.basic.active.button {
  background-color: #edf1f2 !important;
}
.sidebar-container .ui.segment.object-listing {
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: scroll;
}
.sidebar-container ul {
  flex: 1 0 auto;
  margin: 0;
}
.sidebar-container ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 5px;
  cursor: pointer;
}
.sidebar-container ul li.selected-item {
  background-color: #EDF1F2;
}
.sidebar-container ul li.disabled {
  opacity: 0.5;
}
.sidebar-container ul li span {
  display: flex;
  align-items: center;
}
.sidebar-container ul li span .icon {
  padding-right: 10px;
}
.sidebar-container .ui.segment.form.actions,
.sidebar-container .ui.segment.form.sidebar-image-data {
  padding-bottom: 1em;
}
.sidebar-container .ui.accordion .title {
  display: flex;
  justify-content: space-between;
}
.sidebar-container .ui.accordion .content {
  padding: 0;
}
#sidebar .pusher.expanded {
  margin-left: 375px;
}
#sidebar .pusher {
  margin-left: 20px;
  transition: margin-left 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
#sidebar-properties header {
  border-bottom: 2px solid #b8c6c8;
}
.sidebar-container-enter {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  width: 375px;
  height: 100vh;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 #c7d5d8;
  transition: right 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045), height 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045), width 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045), top 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transform: translate(100%, 0%);
}
.sidebar-container-enter.collapsed {
  right: -355px;
}
.sidebar-container-enter.collapsed .tabs-wrapper {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.sidebar-container-enter.full-size {
  width: calc(100% - 80px);
}
.sidebar-container-enter.full-size.no-toolbar {
  width: calc(100% - 20px);
}
.sidebar-container-enter .ui.raised.segments {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.sidebar-container-enter button {
  padding: 0;
  border: 0;
  background: transparent;
  text-align: initial;
}
.sidebar-container-enter button:focus {
  outline: none;
}
.sidebar-container-enter .trigger,
.sidebar-container-enter .trigger:focus,
.sidebar-container-enter .trigger:hover {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0;
  width: 20px;
  height: 80px;
  padding: 0;
  margin-top: -40px;
  background: transparent;
  border-radius: 0;
  opacity: 0.05;
  transition: opacity 0.3s;
}
.sidebar-container-enter .trigger:hover {
  opacity: 0.3;
  transition: opacity 0.3s;
}
.sidebar-container-enter .trigger:before {
  position: relative;
  left: 8px;
  display: block;
  width: 4px;
  height: 100%;
  background: #007EB1;
  content: '';
}
.sidebar-container-enter .tab-wrapper {
  height: calc(100% - 61px);
  padding: 0;
  border-bottom: none;
  overflow-x: hidden;
  overflow-y: auto;
}
.sidebar-container-enter .tab-wrapper > * {
  display: none;
}
.sidebar-container-enter .tab-wrapper > :last-child {
  display: block;
}
.sidebar-container-enter .tabs-wrapper {
  height: 100%;
}
.sidebar-container-enter .tabs-wrapper .formtabs.menu a:first-child {
  margin-left: 36px;
}
.sidebar-container-enter .tab-forbidden {
  margin: calc(50% - 24px) auto;
  color: #bac5c7;
}
.sidebar-container-enter header {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  border-bottom: 4px solid #b8c6c8;
}
.sidebar-container-enter header .icon {
  padding-right: 10px;
}
.sidebar-container-enter header h2 {
  flex: 1 0 auto;
  margin: 0 !important;
  color: #517776;
  font-size: 16px;
}
.sidebar-container-enter header form {
  flex: 1 0 auto;
}
.sidebar-container-enter header .search {
  width: 100%;
}
.sidebar-container-enter header .search input {
  border: none;
}
.sidebar-container-enter header a {
  display: flex;
}
.sidebar-container-enter header.pulled {
  justify-content: flex-start;
  padding-right: 10px;
  padding-left: 10px;
}
.sidebar-container-enter header.pulled button {
  margin-right: 10px;
  cursor: pointer;
}
.sidebar-container-enter .ui.segment:not(.tab) {
  border-bottom: 1px solid #b8c6c8;
}
.sidebar-container-enter .ui.segment.tabbed-actions {
  display: flex;
  justify-content: space-around;
  padding: 0;
  border-top: 1px solid #b8c6c8;
  border-bottom: 1px solid #b8c6c8;
}
.sidebar-container-enter .ui.segment.tabbed-actions .ui.buttons {
  width: 100%;
}
.sidebar-container-enter .ui.segment.tabbed-actions .ui.buttons .button:last-child {
  border-radius: 0;
}
.sidebar-container-enter .ui.segment.tabbed-actions .ui.basic.active.button {
  background-color: #edf1f2 !important;
}
.sidebar-container-enter .ui.segment.object-listing {
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: scroll;
}
.sidebar-container-enter ul {
  flex: 1 0 auto;
  margin: 0;
}
.sidebar-container-enter ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 5px;
  cursor: pointer;
}
.sidebar-container-enter ul li.selected-item {
  background-color: #EDF1F2;
}
.sidebar-container-enter ul li.disabled {
  opacity: 0.5;
}
.sidebar-container-enter ul li span {
  display: flex;
  align-items: center;
}
.sidebar-container-enter ul li span .icon {
  padding-right: 10px;
}
.sidebar-container-enter .ui.segment.form.actions,
.sidebar-container-enter .ui.segment.form.sidebar-image-data {
  padding-bottom: 1em;
}
.sidebar-container-enter .ui.accordion .title {
  display: flex;
  justify-content: space-between;
}
.sidebar-container-enter .ui.accordion .content {
  padding: 0;
}
.sidebar-container-enter.sidebar-container-enter-active {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  width: 375px;
  height: 100vh;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 #c7d5d8;
  transition: right 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045), height 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045), width 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045), top 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transform: translate(0%, 0%);
  transition: transform 0.5s cubic-bezier(0.09, 0.11, 0.24, 0.91);
}
.sidebar-container-enter.sidebar-container-enter-active.collapsed {
  right: -355px;
}
.sidebar-container-enter.sidebar-container-enter-active.collapsed .tabs-wrapper {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.sidebar-container-enter.sidebar-container-enter-active.full-size {
  width: calc(100% - 80px);
}
.sidebar-container-enter.sidebar-container-enter-active.full-size.no-toolbar {
  width: calc(100% - 20px);
}
.sidebar-container-enter.sidebar-container-enter-active .ui.raised.segments {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.sidebar-container-enter.sidebar-container-enter-active button {
  padding: 0;
  border: 0;
  background: transparent;
  text-align: initial;
}
.sidebar-container-enter.sidebar-container-enter-active button:focus {
  outline: none;
}
.sidebar-container-enter.sidebar-container-enter-active .trigger,
.sidebar-container-enter.sidebar-container-enter-active .trigger:focus,
.sidebar-container-enter.sidebar-container-enter-active .trigger:hover {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0;
  width: 20px;
  height: 80px;
  padding: 0;
  margin-top: -40px;
  background: transparent;
  border-radius: 0;
  opacity: 0.05;
  transition: opacity 0.3s;
}
.sidebar-container-enter.sidebar-container-enter-active .trigger:hover {
  opacity: 0.3;
  transition: opacity 0.3s;
}
.sidebar-container-enter.sidebar-container-enter-active .trigger:before {
  position: relative;
  left: 8px;
  display: block;
  width: 4px;
  height: 100%;
  background: #007EB1;
  content: '';
}
.sidebar-container-enter.sidebar-container-enter-active .tab-wrapper {
  height: calc(100% - 61px);
  padding: 0;
  border-bottom: none;
  overflow-x: hidden;
  overflow-y: auto;
}
.sidebar-container-enter.sidebar-container-enter-active .tab-wrapper > * {
  display: none;
}
.sidebar-container-enter.sidebar-container-enter-active .tab-wrapper > :last-child {
  display: block;
}
.sidebar-container-enter.sidebar-container-enter-active .tabs-wrapper {
  height: 100%;
}
.sidebar-container-enter.sidebar-container-enter-active .tabs-wrapper .formtabs.menu a:first-child {
  margin-left: 36px;
}
.sidebar-container-enter.sidebar-container-enter-active .tab-forbidden {
  margin: calc(50% - 24px) auto;
  color: #bac5c7;
}
.sidebar-container-enter.sidebar-container-enter-active header {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  border-bottom: 4px solid #b8c6c8;
}
.sidebar-container-enter.sidebar-container-enter-active header .icon {
  padding-right: 10px;
}
.sidebar-container-enter.sidebar-container-enter-active header h2 {
  flex: 1 0 auto;
  margin: 0 !important;
  color: #517776;
  font-size: 16px;
}
.sidebar-container-enter.sidebar-container-enter-active header form {
  flex: 1 0 auto;
}
.sidebar-container-enter.sidebar-container-enter-active header .search {
  width: 100%;
}
.sidebar-container-enter.sidebar-container-enter-active header .search input {
  border: none;
}
.sidebar-container-enter.sidebar-container-enter-active header a {
  display: flex;
}
.sidebar-container-enter.sidebar-container-enter-active header.pulled {
  justify-content: flex-start;
  padding-right: 10px;
  padding-left: 10px;
}
.sidebar-container-enter.sidebar-container-enter-active header.pulled button {
  margin-right: 10px;
  cursor: pointer;
}
.sidebar-container-enter.sidebar-container-enter-active .ui.segment:not(.tab) {
  border-bottom: 1px solid #b8c6c8;
}
.sidebar-container-enter.sidebar-container-enter-active .ui.segment.tabbed-actions {
  display: flex;
  justify-content: space-around;
  padding: 0;
  border-top: 1px solid #b8c6c8;
  border-bottom: 1px solid #b8c6c8;
}
.sidebar-container-enter.sidebar-container-enter-active .ui.segment.tabbed-actions .ui.buttons {
  width: 100%;
}
.sidebar-container-enter.sidebar-container-enter-active .ui.segment.tabbed-actions .ui.buttons .button:last-child {
  border-radius: 0;
}
.sidebar-container-enter.sidebar-container-enter-active .ui.segment.tabbed-actions .ui.basic.active.button {
  background-color: #edf1f2 !important;
}
.sidebar-container-enter.sidebar-container-enter-active .ui.segment.object-listing {
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: scroll;
}
.sidebar-container-enter.sidebar-container-enter-active ul {
  flex: 1 0 auto;
  margin: 0;
}
.sidebar-container-enter.sidebar-container-enter-active ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 5px;
  cursor: pointer;
}
.sidebar-container-enter.sidebar-container-enter-active ul li.selected-item {
  background-color: #EDF1F2;
}
.sidebar-container-enter.sidebar-container-enter-active ul li.disabled {
  opacity: 0.5;
}
.sidebar-container-enter.sidebar-container-enter-active ul li span {
  display: flex;
  align-items: center;
}
.sidebar-container-enter.sidebar-container-enter-active ul li span .icon {
  padding-right: 10px;
}
.sidebar-container-enter.sidebar-container-enter-active .ui.segment.form.actions,
.sidebar-container-enter.sidebar-container-enter-active .ui.segment.form.sidebar-image-data {
  padding-bottom: 1em;
}
.sidebar-container-enter.sidebar-container-enter-active .ui.accordion .title {
  display: flex;
  justify-content: space-between;
}
.sidebar-container-enter.sidebar-container-enter-active .ui.accordion .content {
  padding: 0;
}
.sidebar-container-enter-done {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  width: 375px;
  height: 100vh;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 #c7d5d8;
  transition: right 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045), height 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045), width 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045), top 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.sidebar-container-enter-done.collapsed {
  right: -355px;
}
.sidebar-container-enter-done.collapsed .tabs-wrapper {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.sidebar-container-enter-done.full-size {
  width: calc(100% - 80px);
}
.sidebar-container-enter-done.full-size.no-toolbar {
  width: calc(100% - 20px);
}
.sidebar-container-enter-done .ui.raised.segments {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.sidebar-container-enter-done button {
  padding: 0;
  border: 0;
  background: transparent;
  text-align: initial;
}
.sidebar-container-enter-done button:focus {
  outline: none;
}
.sidebar-container-enter-done .trigger,
.sidebar-container-enter-done .trigger:focus,
.sidebar-container-enter-done .trigger:hover {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0;
  width: 20px;
  height: 80px;
  padding: 0;
  margin-top: -40px;
  background: transparent;
  border-radius: 0;
  opacity: 0.05;
  transition: opacity 0.3s;
}
.sidebar-container-enter-done .trigger:hover {
  opacity: 0.3;
  transition: opacity 0.3s;
}
.sidebar-container-enter-done .trigger:before {
  position: relative;
  left: 8px;
  display: block;
  width: 4px;
  height: 100%;
  background: #007EB1;
  content: '';
}
.sidebar-container-enter-done .tab-wrapper {
  height: calc(100% - 61px);
  padding: 0;
  border-bottom: none;
  overflow-x: hidden;
  overflow-y: auto;
}
.sidebar-container-enter-done .tab-wrapper > * {
  display: none;
}
.sidebar-container-enter-done .tab-wrapper > :last-child {
  display: block;
}
.sidebar-container-enter-done .tabs-wrapper {
  height: 100%;
}
.sidebar-container-enter-done .tabs-wrapper .formtabs.menu a:first-child {
  margin-left: 36px;
}
.sidebar-container-enter-done .tab-forbidden {
  margin: calc(50% - 24px) auto;
  color: #bac5c7;
}
.sidebar-container-enter-done header {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  border-bottom: 4px solid #b8c6c8;
}
.sidebar-container-enter-done header .icon {
  padding-right: 10px;
}
.sidebar-container-enter-done header h2 {
  flex: 1 0 auto;
  margin: 0 !important;
  color: #517776;
  font-size: 16px;
}
.sidebar-container-enter-done header form {
  flex: 1 0 auto;
}
.sidebar-container-enter-done header .search {
  width: 100%;
}
.sidebar-container-enter-done header .search input {
  border: none;
}
.sidebar-container-enter-done header a {
  display: flex;
}
.sidebar-container-enter-done header.pulled {
  justify-content: flex-start;
  padding-right: 10px;
  padding-left: 10px;
}
.sidebar-container-enter-done header.pulled button {
  margin-right: 10px;
  cursor: pointer;
}
.sidebar-container-enter-done .ui.segment:not(.tab) {
  border-bottom: 1px solid #b8c6c8;
}
.sidebar-container-enter-done .ui.segment.tabbed-actions {
  display: flex;
  justify-content: space-around;
  padding: 0;
  border-top: 1px solid #b8c6c8;
  border-bottom: 1px solid #b8c6c8;
}
.sidebar-container-enter-done .ui.segment.tabbed-actions .ui.buttons {
  width: 100%;
}
.sidebar-container-enter-done .ui.segment.tabbed-actions .ui.buttons .button:last-child {
  border-radius: 0;
}
.sidebar-container-enter-done .ui.segment.tabbed-actions .ui.basic.active.button {
  background-color: #edf1f2 !important;
}
.sidebar-container-enter-done .ui.segment.object-listing {
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: scroll;
}
.sidebar-container-enter-done ul {
  flex: 1 0 auto;
  margin: 0;
}
.sidebar-container-enter-done ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 5px;
  cursor: pointer;
}
.sidebar-container-enter-done ul li.selected-item {
  background-color: #EDF1F2;
}
.sidebar-container-enter-done ul li.disabled {
  opacity: 0.5;
}
.sidebar-container-enter-done ul li span {
  display: flex;
  align-items: center;
}
.sidebar-container-enter-done ul li span .icon {
  padding-right: 10px;
}
.sidebar-container-enter-done .ui.segment.form.actions,
.sidebar-container-enter-done .ui.segment.form.sidebar-image-data {
  padding-bottom: 1em;
}
.sidebar-container-enter-done .ui.accordion .title {
  display: flex;
  justify-content: space-between;
}
.sidebar-container-enter-done .ui.accordion .content {
  padding: 0;
}
.sidebar-container-exit {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  width: 375px;
  height: 100vh;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 #c7d5d8;
  transition: right 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045), height 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045), width 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045), top 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transform: translate(0%, 0%);
  transition: transform 0.5s cubic-bezier(0.09, 0.11, 0.24, 0.91);
}
.sidebar-container-exit.collapsed {
  right: -355px;
}
.sidebar-container-exit.collapsed .tabs-wrapper {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.sidebar-container-exit.full-size {
  width: calc(100% - 80px);
}
.sidebar-container-exit.full-size.no-toolbar {
  width: calc(100% - 20px);
}
.sidebar-container-exit .ui.raised.segments {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.sidebar-container-exit button {
  padding: 0;
  border: 0;
  background: transparent;
  text-align: initial;
}
.sidebar-container-exit button:focus {
  outline: none;
}
.sidebar-container-exit .trigger,
.sidebar-container-exit .trigger:focus,
.sidebar-container-exit .trigger:hover {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0;
  width: 20px;
  height: 80px;
  padding: 0;
  margin-top: -40px;
  background: transparent;
  border-radius: 0;
  opacity: 0.05;
  transition: opacity 0.3s;
}
.sidebar-container-exit .trigger:hover {
  opacity: 0.3;
  transition: opacity 0.3s;
}
.sidebar-container-exit .trigger:before {
  position: relative;
  left: 8px;
  display: block;
  width: 4px;
  height: 100%;
  background: #007EB1;
  content: '';
}
.sidebar-container-exit .tab-wrapper {
  height: calc(100% - 61px);
  padding: 0;
  border-bottom: none;
  overflow-x: hidden;
  overflow-y: auto;
}
.sidebar-container-exit .tab-wrapper > * {
  display: none;
}
.sidebar-container-exit .tab-wrapper > :last-child {
  display: block;
}
.sidebar-container-exit .tabs-wrapper {
  height: 100%;
}
.sidebar-container-exit .tabs-wrapper .formtabs.menu a:first-child {
  margin-left: 36px;
}
.sidebar-container-exit .tab-forbidden {
  margin: calc(50% - 24px) auto;
  color: #bac5c7;
}
.sidebar-container-exit header {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  border-bottom: 4px solid #b8c6c8;
}
.sidebar-container-exit header .icon {
  padding-right: 10px;
}
.sidebar-container-exit header h2 {
  flex: 1 0 auto;
  margin: 0 !important;
  color: #517776;
  font-size: 16px;
}
.sidebar-container-exit header form {
  flex: 1 0 auto;
}
.sidebar-container-exit header .search {
  width: 100%;
}
.sidebar-container-exit header .search input {
  border: none;
}
.sidebar-container-exit header a {
  display: flex;
}
.sidebar-container-exit header.pulled {
  justify-content: flex-start;
  padding-right: 10px;
  padding-left: 10px;
}
.sidebar-container-exit header.pulled button {
  margin-right: 10px;
  cursor: pointer;
}
.sidebar-container-exit .ui.segment:not(.tab) {
  border-bottom: 1px solid #b8c6c8;
}
.sidebar-container-exit .ui.segment.tabbed-actions {
  display: flex;
  justify-content: space-around;
  padding: 0;
  border-top: 1px solid #b8c6c8;
  border-bottom: 1px solid #b8c6c8;
}
.sidebar-container-exit .ui.segment.tabbed-actions .ui.buttons {
  width: 100%;
}
.sidebar-container-exit .ui.segment.tabbed-actions .ui.buttons .button:last-child {
  border-radius: 0;
}
.sidebar-container-exit .ui.segment.tabbed-actions .ui.basic.active.button {
  background-color: #edf1f2 !important;
}
.sidebar-container-exit .ui.segment.object-listing {
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: scroll;
}
.sidebar-container-exit ul {
  flex: 1 0 auto;
  margin: 0;
}
.sidebar-container-exit ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 5px;
  cursor: pointer;
}
.sidebar-container-exit ul li.selected-item {
  background-color: #EDF1F2;
}
.sidebar-container-exit ul li.disabled {
  opacity: 0.5;
}
.sidebar-container-exit ul li span {
  display: flex;
  align-items: center;
}
.sidebar-container-exit ul li span .icon {
  padding-right: 10px;
}
.sidebar-container-exit .ui.segment.form.actions,
.sidebar-container-exit .ui.segment.form.sidebar-image-data {
  padding-bottom: 1em;
}
.sidebar-container-exit .ui.accordion .title {
  display: flex;
  justify-content: space-between;
}
.sidebar-container-exit .ui.accordion .content {
  padding: 0;
}
.sidebar-container-exit.sidebar-container-exit-active {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  width: 375px;
  height: 100vh;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 #c7d5d8;
  transition: right 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045), height 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045), width 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045), top 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transform: translate(100%, 0%);
  transition: transform 0.5s cubic-bezier(0.09, 0.11, 0.24, 0.91);
}
.sidebar-container-exit.sidebar-container-exit-active.collapsed {
  right: -355px;
}
.sidebar-container-exit.sidebar-container-exit-active.collapsed .tabs-wrapper {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.sidebar-container-exit.sidebar-container-exit-active.full-size {
  width: calc(100% - 80px);
}
.sidebar-container-exit.sidebar-container-exit-active.full-size.no-toolbar {
  width: calc(100% - 20px);
}
.sidebar-container-exit.sidebar-container-exit-active .ui.raised.segments {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.sidebar-container-exit.sidebar-container-exit-active button {
  padding: 0;
  border: 0;
  background: transparent;
  text-align: initial;
}
.sidebar-container-exit.sidebar-container-exit-active button:focus {
  outline: none;
}
.sidebar-container-exit.sidebar-container-exit-active .trigger,
.sidebar-container-exit.sidebar-container-exit-active .trigger:focus,
.sidebar-container-exit.sidebar-container-exit-active .trigger:hover {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0;
  width: 20px;
  height: 80px;
  padding: 0;
  margin-top: -40px;
  background: transparent;
  border-radius: 0;
  opacity: 0.05;
  transition: opacity 0.3s;
}
.sidebar-container-exit.sidebar-container-exit-active .trigger:hover {
  opacity: 0.3;
  transition: opacity 0.3s;
}
.sidebar-container-exit.sidebar-container-exit-active .trigger:before {
  position: relative;
  left: 8px;
  display: block;
  width: 4px;
  height: 100%;
  background: #007EB1;
  content: '';
}
.sidebar-container-exit.sidebar-container-exit-active .tab-wrapper {
  height: calc(100% - 61px);
  padding: 0;
  border-bottom: none;
  overflow-x: hidden;
  overflow-y: auto;
}
.sidebar-container-exit.sidebar-container-exit-active .tab-wrapper > * {
  display: none;
}
.sidebar-container-exit.sidebar-container-exit-active .tab-wrapper > :last-child {
  display: block;
}
.sidebar-container-exit.sidebar-container-exit-active .tabs-wrapper {
  height: 100%;
}
.sidebar-container-exit.sidebar-container-exit-active .tabs-wrapper .formtabs.menu a:first-child {
  margin-left: 36px;
}
.sidebar-container-exit.sidebar-container-exit-active .tab-forbidden {
  margin: calc(50% - 24px) auto;
  color: #bac5c7;
}
.sidebar-container-exit.sidebar-container-exit-active header {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  border-bottom: 4px solid #b8c6c8;
}
.sidebar-container-exit.sidebar-container-exit-active header .icon {
  padding-right: 10px;
}
.sidebar-container-exit.sidebar-container-exit-active header h2 {
  flex: 1 0 auto;
  margin: 0 !important;
  color: #517776;
  font-size: 16px;
}
.sidebar-container-exit.sidebar-container-exit-active header form {
  flex: 1 0 auto;
}
.sidebar-container-exit.sidebar-container-exit-active header .search {
  width: 100%;
}
.sidebar-container-exit.sidebar-container-exit-active header .search input {
  border: none;
}
.sidebar-container-exit.sidebar-container-exit-active header a {
  display: flex;
}
.sidebar-container-exit.sidebar-container-exit-active header.pulled {
  justify-content: flex-start;
  padding-right: 10px;
  padding-left: 10px;
}
.sidebar-container-exit.sidebar-container-exit-active header.pulled button {
  margin-right: 10px;
  cursor: pointer;
}
.sidebar-container-exit.sidebar-container-exit-active .ui.segment:not(.tab) {
  border-bottom: 1px solid #b8c6c8;
}
.sidebar-container-exit.sidebar-container-exit-active .ui.segment.tabbed-actions {
  display: flex;
  justify-content: space-around;
  padding: 0;
  border-top: 1px solid #b8c6c8;
  border-bottom: 1px solid #b8c6c8;
}
.sidebar-container-exit.sidebar-container-exit-active .ui.segment.tabbed-actions .ui.buttons {
  width: 100%;
}
.sidebar-container-exit.sidebar-container-exit-active .ui.segment.tabbed-actions .ui.buttons .button:last-child {
  border-radius: 0;
}
.sidebar-container-exit.sidebar-container-exit-active .ui.segment.tabbed-actions .ui.basic.active.button {
  background-color: #edf1f2 !important;
}
.sidebar-container-exit.sidebar-container-exit-active .ui.segment.object-listing {
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: scroll;
}
.sidebar-container-exit.sidebar-container-exit-active ul {
  flex: 1 0 auto;
  margin: 0;
}
.sidebar-container-exit.sidebar-container-exit-active ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 5px;
  cursor: pointer;
}
.sidebar-container-exit.sidebar-container-exit-active ul li.selected-item {
  background-color: #EDF1F2;
}
.sidebar-container-exit.sidebar-container-exit-active ul li.disabled {
  opacity: 0.5;
}
.sidebar-container-exit.sidebar-container-exit-active ul li span {
  display: flex;
  align-items: center;
}
.sidebar-container-exit.sidebar-container-exit-active ul li span .icon {
  padding-right: 10px;
}
.sidebar-container-exit.sidebar-container-exit-active .ui.segment.form.actions,
.sidebar-container-exit.sidebar-container-exit-active .ui.segment.form.sidebar-image-data {
  padding-bottom: 1em;
}
.sidebar-container-exit.sidebar-container-exit-active .ui.accordion .title {
  display: flex;
  justify-content: space-between;
}
.sidebar-container-exit.sidebar-container-exit-active .ui.accordion .content {
  padding: 0;
}
.sidebar-metadata-container {
  display: flex;
  justify-content: space-between;
}
.field-file-name {
  display: flex;
  align-items: flex-end;
  padding: 20px 0;
}
.align-tools {
  display: flex !important;
  align-items: center;
}
.align-tools .ui.icon.button {
  padding: 4px;
  margin-left: 4px;
  border-radius: 1px;
  color: #826A6A !important;
}
.align-tools .ui.icon.button:hover {
  background-color: #edf1f2 !important;
}
.align-tools .ui.buttons:first-child .ui.icon.button {
  margin-left: 0;
}
.align-tools .ui.active.basic.icon.button {
  border: 1px solid #2996da;
  background: transparent !important;
}
.ui.icon.input + button {
  position: absolute;
  top: 0;
  right: 10px;
  width: 2.67142857em;
  height: 100%;
  padding: 4px;
  margin: 0;
  border-radius: 1px;
  color: #826A6A !important;
  line-height: 1;
  text-align: center;
}
.ui.icon.input + button:hover {
  background-color: #edf1f2 !important;
}
.ui.input.date-input,
.SingleDatePicker,
.SingleDatePickerInput,
.DateInput,
.DateInput_input {
  width: 100%;
  max-width: 100%;
}
.DayPickerNavigation_button {
  border: 0;
}
td.CalendarDay {
  border: 0;
}
td.CalendarDay__selected,
td.CalendarDay__selected:active,
td.CalendarDay__selected:hover {
  position: relative;
  border: 0;
  background: transparent;
  color: inherit;
  color: #007EB1;
  font-weight: bold;
}
td.CalendarDay__selected::before {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  border: 2px solid #007EB1;
  border-radius: 100%;
  content: '';
}
td.CalendarDay__today {
  font-weight: bold;
}
.DayPickerKeyboardShortcuts_show__bottomRight {
  width: 2.5rem;
  height: 2.5rem;
}
.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-top-width: 2.5rem;
  border-right-width: 2.5rem;
  border-right-color: #007EB1;
}
.DayPickerKeyboardShortcuts_show__bottomRight:hover::before,
.DayPickerKeyboardShortcuts_show__bottomRight:focus::before {
  border-right: 2.5rem solid #0090cb;
}
.DayPicker.DayPicker__withBorder {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 2px 20px 0 rgba(0, 0, 0, 0.2);
}
.DateInput_fang {
  display: none;
}
.default-date .DateInput input.DateInput_input[type='text'] {
  color: #878F93;
}
.CalendarMonth .CalendarMonth_caption {
  padding-bottom: 22px;
  margin-bottom: 45px;
  border-bottom: 1px solid #edf1f2;
}
.CalendarMonth_caption strong {
  color: #252525;
  font-family: 'FuturaPT-Book';
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  letter-spacing: normal;
  line-height: 1.07;
}
ul.DayPicker_weekHeader_ul {
  display: flex;
  padding-top: 0.5rem;
}
.ui.input.time-input {
  max-width: 100%;
}
.rc-time-picker {
  width: 100%;
}
.rc-time-picker input.rc-time-picker-input {
  max-width: 100%;
}
.default-date .rc-time-picker input.rc-time-picker-input[type='text'] {
  color: #878F93;
}
.rc-time-picker-panel {
  width: 300px;
}
.rc-time-picker-panel .rc-time-picker-panel-inner {
  width: 300px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 2px 20px 0 rgba(0, 0, 0, 0.2);
}
.rc-time-picker-panel-narrow {
  width: 240px;
  max-width: none;
}
.rc-time-picker-panel-narrow .rc-time-picker-panel-inner {
  width: 240px;
}
.rc-time-picker-panel .rc-time-picker-panel-input-wrap {
  border-bottom: solid 1px #edf1f2;
}
.rc-time-picker-panel .rc-time-picker-panel-combobox {
  position: relative;
  display: flex;
  overflow: hidden;
}
.rc-time-picker-panel .rc-time-picker-panel-combobox::before {
  position: absolute;
  top: -5px;
  left: 0;
  display: block;
  height: 5px;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.4);
  content: '';
}
.rc-time-picker-panel input.rc-time-picker-panel-input {
  padding: 0.5rem 0.25rem;
  font-size: 1.25rem;
  text-align: center;
}
.rc-time-picker-panel .rc-time-picker-panel-select {
  width: 100%;
}
.rc-time-picker-panel .rc-time-picker-panel-select ul li {
  height: auto;
  padding: 0.25rem;
  text-align: center;
}
.rc-time-picker-panel .rc-time-picker-panel-select ul li.rc-time-picker-panel-select-option-selected {
  width: calc(100% - 1rem);
  padding: 0;
  margin: 0.5rem;
  border: 2px solid #007EB1;
  background: none;
  border-radius: 1rem;
  color: #007EB1;
}
.section-sitemap #page-sitemap ul {
  line-height: 2.5em;
  list-style-type: none;
}
.section-sitemap #page-sitemap ul li {
  font-weight: bold;
}
.section-sitemap #page-sitemap ul li ul li {
  font-weight: normal;
}
.section-sitemap #page-sitemap li.with-children {
  font-weight: bold;
}
.section-search .items_total {
  margin-bottom: 1em;
  color: #68778D;
}
.section-search #content-core .tileItem {
  margin-bottom: 1.5em;
}
.section-search #content-core .tileItem h2 {
  margin-bottom: 0.5rem;
}
.section-search .search-footer {
  text-align: center;
}
.folder-contents .ui.icon.button,
.folder-contents .ui.icon.button:hover {
  background: transparent !important;
  box-shadow: none;
}
.folder-contents .ui.compact.table td {
  padding: 8px;
}
.folder-contents .ui.compact.table td .row-actions {
  height: 24px;
  padding-top: 4px;
}
.folder-contents .ui.dropdown .menu > .item {
  display: flex;
  align-items: center;
}
.folder-contents .ui.dropdown > .left.menu .menu {
  margin: 0 !important;
}
.folder-contents .ui.menu.top-menu {
  padding: 10px 0;
  border-bottom: 4px solid #c7d5d8;
}
.folder-contents .ui.menu .menu.top-menu-menu {
  border-right: 1px solid #b8c6c8;
}
.folder-contents .ui.menu .menu.top-menu-menu .ui.icon.button {
  padding: 10px;
  margin-left: 4px;
  border-radius: 1px;
  color: #826A6A !important;
}
.folder-contents .ui.menu .menu.top-menu-menu .ui.icon.button:hover {
  background-color: #edf1f2 !important;
}
.folder-contents .ui.menu .menu.top-menu-searchbox {
  border-left: 1px solid #b8c6c8;
}
.folder-contents .ui.secondary.attached.segment.contents-breadcrumbs {
  display: flex;
  justify-content: space-between;
}
.folder-contents .ui.secondary.attached.segment.contents-breadcrumbs .ui.dropdown {
  height: 28px;
}
.folder-contents .ui.menu .item.upload:hover,
.folder-contents .ui.menu .item.paste:hover {
  background: none;
}
.folder-contents .ui.dropdown .menu > .item.left-dropdown > svg {
  position: relative;
  top: 3px;
}
.folder-contents .ui.table .icon-align,
.folder-contents .ui.dropdown .menu > .item.icon-align {
  display: flex;
  align-items: center;
  padding-top: 4px;
}
.folder-contents .ui.table .icon-align-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 4px;
}
.folder-contents .ui.table .expire-align {
  display: flex;
  align-items: center;
}
.folder-contents .ui.table .icon-margin {
  margin-right: 0.3em;
}
.folder-contents .ui.table .button-margin {
  padding: 4px;
  margin-right: 1em;
  background-color: #e40166;
  color: white;
}
.contents-pagination .ui.secondary.menu .item {
  color: #878f93;
}
.contents-pagination .ui.secondary.menu a.item,
.contents-pagination .ui.secondary.menu a.item.active {
  color: #007EB1;
}
.contents-pagination .ui.secondary.menu a.item:hover,
.contents-pagination .ui.secondary.menu a.item.active {
  background-color: #edf1f2 !important;
}
@media print {
  .ui.pointing.secondary.stackable.computer.large.screen.widescreen.only.menu,
  .ui.basic.segment .header .tools-search-wrapper,
  .ui.secondary.vertical.segment.breadcrumbs,
  .hamburger,
  .ui.inverted.grey.segment,
  #toolbar {
    display: none;
  }
  .logo {
    margin-left: -1.2em;
  }
}
/* stylelint-disable */
/*******************************
            Theme
*******************************/
/*******************************
        Theme Selection
*******************************/
/* To override a theme for an individual element specify theme name below */
/* Global */
/* Elements */
/* Collections */
/* Modules */
/* Views */
/* Extras */
/*******************************
            Folders
*******************************/
/* Path to theme packages */
/* Path to site override folder */
/*******************************
         Import Theme
*******************************/
/*******************************
        Import Directives
*******************************/
/*------------------
       Theme
-------------------*/
/*--------------------
   Site Variables
---------------------*/
/* Default site.variables */
/*******************************
         Site Settings
*******************************/
/*-------------------
       Fonts
--------------------*/
/*-------------------
      Base Sizes
--------------------*/
/* This is the single variable that controls them all */
/* The size of page text  */
/*-------------------
    Border Radius
--------------------*/
/* See Power-user section below
   for explanation of @px variables
*/
/*-------------------
    Brand Colors
--------------------*/
/*--------------
  Page Heading
---------------*/
/*--------------
   Form Input
---------------*/
/* This adjusts the default form input across all elements */
/* Input Text Color */
/* Line Height Default For Inputs in Browser (Descenders are 17px at 14px base em) */
/*-------------------
    Focused Input
--------------------*/
/* Used on inputs, textarea etc */
/* Used on dropdowns, other larger blocks */
/*-------------------
        Sizes
--------------------*/
/*
  Sizes are all expressed in terms of 14px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/
/*-------------------
        Page
--------------------*/
/*-------------------
      Paragraph
--------------------*/
/*-------------------
       Links
--------------------*/
/*-------------------
    Scroll Bars
--------------------*/
/* Inverted */
/*-------------------
  Highlighted Text
--------------------*/
/*-------------------
       Loader
--------------------*/
/*-------------------
        Grid
--------------------*/
/*-------------------
     Transitions
--------------------*/
/*-------------------
     Breakpoints
--------------------*/
/*-------------------
      Site Colors
--------------------*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Headers ---*/
/*--- Colored Text ---*/
/*--- Colored Border ---*/
/*-------------------
     Alpha Colors
--------------------*/
/*-------------------
       Accents
--------------------*/
/* Differentiating Neutrals */
/* Differentiating Layers */
/*******************************
           Power-User
*******************************/
/*-------------------
    Emotive Colors
--------------------*/
/* Positive */
/* Negative */
/* Info */
/* Warning */
/*-------------------
        Paths
--------------------*/
/* For source only. Modified in gulp for dist */
/*-------------------
       Em Sizes
--------------------*/
/*
  This rounds @size values to the closest pixel then expresses that value in (r)em.
  This ensures all size values round to exact pixels
*/
/* em */
/* rem */
/*-------------------
       Icons
--------------------*/
/* Maximum Glyph Width of Icon */
/*-------------------
     Neutral Text
--------------------*/
/*-------------------
     Brand Colors
--------------------*/
/*-------------------
      Borders
--------------------*/
/*-------------------
    Derived Values
--------------------*/
/* Loaders Position Offset */
/* Rendered Scrollbar Width */
/* Maximum Single Character Glyph Width, aka Capital "W" */
/* Used to match floats with text */
/* Header Spacing */
/* Minimum Mobile Width */
/* Positive / Negative Dupes */
/* Responsive */
/*-------------------
  Exact Pixel Values
--------------------*/
/*
  These are used to specify exact pixel values in em
  for things like borders that remain constantly
  sized as emSize adjusts

  Since there are many more sizes than names for sizes,
  these are named by their original pixel values.

*/
/* Columns */
/*******************************
             States
*******************************/
/*-------------------
      Disabled
--------------------*/
/*-------------------
        Hover
--------------------*/
/*---  Shadows  ---*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Focus
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
    Down (:active)
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Active
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/* Packaged site.variables */
/*******************************
         Site Settings
*******************************/
/*-------------------
       Fonts
--------------------*/
/*-------------------
      Base Sizes
--------------------*/
/*-------------------
    Border Radius
--------------------*/
/*-------------------
    Brand Colors
--------------------*/
/*--------------
  Page Heading
---------------*/
/*--------------
   Form Input
---------------*/
/* This adjusts the default form input across all elements */
/* Input Text Color */
/*-------------------
    Focused Input
--------------------*/
/*-------------------
        Sizes
--------------------*/
/*
  Sizes are all expressed in terms of 14px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/
/*-------------------
        Page
--------------------*/
/*-------------------
      Paragraph
--------------------*/
/*-------------------
       Links
--------------------*/
/*-------------------
    Scroll Bars
--------------------*/
/*-------------------
  Highlighted Text
--------------------*/
/*-------------------
       Loader
--------------------*/
/*-------------------
        Grid
--------------------*/
/*-------------------
     Transitions
--------------------*/
/*-------------------
     Breakpoints
--------------------*/
/*-------------------
      Site Colors
--------------------*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Headers ---*/
/*--- Colored Text ---*/
/*--- Colored Border ---*/
/*-------------------
     Alpha Colors
--------------------*/
/*-------------------
       Accents
--------------------*/
/*******************************
           Power-User
*******************************/
/*-------------------
    Emotive Colors
--------------------*/
/* Positive */
/* Negative */
/* Info */
/* Warning */
/*-------------------
        Paths
--------------------*/
/*-------------------
       Em Sizes
--------------------*/
/* em */
/* rem */
/*-------------------
       Icons
--------------------*/
/*-------------------
     Neutral Text
--------------------*/
/*-------------------
     Brand Colors
--------------------*/
/*-------------------
      Borders
--------------------*/
/*-------------------
    Derived Values
--------------------*/
/* Loaders Position Offset */
/* Rendered Scrollbar Width */
/* Maximum Single Character Glyph Width, aka Capital "W" */
/* Used to match floats with text */
/* Header Spacing */
/* Minimum Mobile Width */
/* Positive / Negative Dupes */
/* Responsive */
/*-------------------
  Exact Pixel Values
--------------------*/
/*
  These are used to specify exact pixel values in em
  for things like borders that remain constantly
  sized as emSize adjusts

  Since there are many more sizes than names for sizes,
  these are named by their original pixel values.

*/
/* Columns */
/*******************************
             States
*******************************/
/*-------------------
      Disabled
--------------------*/
/*-------------------
        Hover
--------------------*/
/*---  Shadows  ---*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Focus
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
    Down (:active)
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Active
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/* Component's site.variables */
/*******************************
         Site Settings
*******************************/
/*-------------------
       Fonts
--------------------*/
/*-------------------
      Base Sizes
--------------------*/
/*-------------------
    Border Radius
--------------------*/
/*-------------------
    Brand Colors
--------------------*/
/*--------------
  Page Heading
---------------*/
/*--------------
   Form Input
---------------*/
/* This adjusts the default form input across all elements */
/* Input Text Color */
/*-------------------
    Focused Input
--------------------*/
/*-------------------
        Sizes
--------------------*/
/*
  Sizes are all expressed in terms of 14px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/
/*-------------------
        Page
--------------------*/
/*-------------------
      Paragraph
--------------------*/
/*-------------------
       Links
--------------------*/
/*-------------------
    Scroll Bars
--------------------*/
/*-------------------
  Highlighted Text
--------------------*/
/*-------------------
       Loader
--------------------*/
/*-------------------
        Grid
--------------------*/
/*-------------------
     Transitions
--------------------*/
/*-------------------
     Breakpoints
--------------------*/
/*-------------------
      Site Colors
--------------------*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Headers ---*/
/*--- Colored Text ---*/
/*--- Colored Border ---*/
/*-------------------
     Alpha Colors
--------------------*/
/*-------------------
       Accents
--------------------*/
/*******************************
           Power-User
*******************************/
/*-------------------
    Emotive Colors
--------------------*/
/* Positive */
/* Negative */
/* Info */
/* Warning */
/*-------------------
        Paths
--------------------*/
/*-------------------
       Em Sizes
--------------------*/
/* em */
/* rem */
/*-------------------
       Icons
--------------------*/
/*-------------------
     Neutral Text
--------------------*/
/*-------------------
     Brand Colors
--------------------*/
/*-------------------
      Borders
--------------------*/
/*-------------------
    Derived Values
--------------------*/
/* Loaders Position Offset */
/* Rendered Scrollbar Width */
/* Maximum Single Character Glyph Width, aka Capital "W" */
/* Used to match floats with text */
/* Header Spacing */
/* Minimum Mobile Width */
/* Positive / Negative Dupes */
/* Responsive */
/*-------------------
  Exact Pixel Values
--------------------*/
/*
  These are used to specify exact pixel values in em
  for things like borders that remain constantly
  sized as emSize adjusts

  Since there are many more sizes than names for sizes,
  these are named by their original pixel values.

*/
/* Columns */
/*******************************
             States
*******************************/
/*-------------------
      Disabled
--------------------*/
/*-------------------
        Hover
--------------------*/
/*---  Shadows  ---*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Focus
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
    Down (:active)
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Active
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/* Site theme site.variables */
/*-------------------
 Component Variables
---------------------*/
/* Default */
/* Packaged Theme */
/* Site Theme */
/*******************************
             Mix-ins
*******************************/
/*------------------
       Fonts
-------------------*/
/*------------------
     Overrides
-------------------*/
/* End Config */
/*******************************
            Container
*******************************/
/*-------------------
       Element
--------------------*/
/* Minimum Gutter is used to determine  the maximum container width for a given device */
/* Devices */
/* Coupling (Add Negative Margin to container size) */
/*-------------------
       Types
--------------------*/
/* Text */
body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) {
  /* Disable full-width at 1440 */
}
@media only screen and (max-width: 1439px) {
  body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) {
    /* Main frame fluid up to 1440 */
  }
  body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) .header-wrapper .ui.container {
    width: 100% !important;
  }
}
@media only screen and (min-width: 1440px) {
  body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) #page-document.ui.container {
    width: 921px !important;
  }
}
@media only screen and (min-width: 1440px) {
  body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) .full-width {
    position: static;
    width: initial !important;
  }
  body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) .fix-width-issue {
    width: 1351px;
  }
  body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) .block.hero,
  body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) .job-filter-block,
  body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) .benefits,
  body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) .block.maps.align.full,
  body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) .block.video.align.full,
  body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) .block.header-wrapper,
  body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) .block.aktuelles,
  body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) .block.meldungen,
  body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) .block.image {
    display: flex;
    width: 100% !important;
    justify-content: center;
  }
  body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) .block.hero .full-width,
  body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) .job-filter-block .full-width,
  body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) .benefits .full-width,
  body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) .block.maps.align.full .full-width,
  body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) .block.video.align.full .full-width,
  body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) .block.header-wrapper .full-width,
  body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) .block.aktuelles .full-width,
  body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) .block.meldungen .full-width,
  body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) .block.image .full-width {
    width: 1351px !important;
  }
}
body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) {
  /* Disable full-width at 1440 */
}
@media only screen and (max-width: 1519px) {
  body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) {
    /* Main frame fluid up to 1440 */
  }
  body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) .header-wrapper .ui.container {
    width: 100% !important;
  }
}
@media only screen and (min-width: 1520px) {
  body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) #page-document.ui.container {
    width: 921px !important;
  }
}
@media only screen and (min-width: 1520px) {
  body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) .full-width {
    position: static;
    width: initial !important;
  }
  body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) .fix-width-issue {
    width: 1351px;
  }
  body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) .block.hero,
  body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) .job-filter-block,
  body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) .benefits,
  body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) .block.maps.align.full,
  body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) .block.video.align.full,
  body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) .block.header-wrapper,
  body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) .block.aktuelles,
  body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) .block.meldungen,
  body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) .block.image {
    display: flex;
    width: 100% !important;
    justify-content: center;
  }
  body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) .block.hero .full-width,
  body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) .job-filter-block .full-width,
  body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) .benefits .full-width,
  body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) .block.maps.align.full .full-width,
  body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) .block.video.align.full .full-width,
  body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) .block.header-wrapper .full-width,
  body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) .block.aktuelles .full-width,
  body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) .block.meldungen .full-width,
  body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) .block.image .full-width {
    width: 1351px !important;
  }
}
body.has-toolbar.has-sidebar {
  /* Disable full-width at 1440 */
}
@media only screen and (max-width: 1894px) {
  body.has-toolbar.has-sidebar {
    /* Main frame fluid up to 1440 */
  }
  body.has-toolbar.has-sidebar .header-wrapper .ui.container {
    width: 100% !important;
  }
}
@media only screen and (min-width: 1895px) {
  body.has-toolbar.has-sidebar #page-document.ui.container {
    width: 921px !important;
  }
}
@media only screen and (min-width: 1895px) {
  body.has-toolbar.has-sidebar .full-width {
    position: static;
    width: initial !important;
  }
  body.has-toolbar.has-sidebar .fix-width-issue {
    width: 1351px;
  }
  body.has-toolbar.has-sidebar .block.hero,
  body.has-toolbar.has-sidebar .job-filter-block,
  body.has-toolbar.has-sidebar .benefits,
  body.has-toolbar.has-sidebar .block.maps.align.full,
  body.has-toolbar.has-sidebar .block.video.align.full,
  body.has-toolbar.has-sidebar .block.header-wrapper,
  body.has-toolbar.has-sidebar .block.aktuelles,
  body.has-toolbar.has-sidebar .block.meldungen,
  body.has-toolbar.has-sidebar .block.image {
    display: flex;
    width: 100% !important;
    justify-content: center;
  }
  body.has-toolbar.has-sidebar .block.hero .full-width,
  body.has-toolbar.has-sidebar .job-filter-block .full-width,
  body.has-toolbar.has-sidebar .benefits .full-width,
  body.has-toolbar.has-sidebar .block.maps.align.full .full-width,
  body.has-toolbar.has-sidebar .block.video.align.full .full-width,
  body.has-toolbar.has-sidebar .block.header-wrapper .full-width,
  body.has-toolbar.has-sidebar .block.aktuelles .full-width,
  body.has-toolbar.has-sidebar .block.meldungen .full-width,
  body.has-toolbar.has-sidebar .block.image .full-width {
    width: 1351px !important;
  }
}
body.has-toolbar.has-sidebar-collapsed {
  /* Disable full-width at 1440 */
}
@media only screen and (max-width: 1539px) {
  body.has-toolbar.has-sidebar-collapsed {
    /* Main frame fluid up to 1440 */
  }
  body.has-toolbar.has-sidebar-collapsed .header-wrapper .ui.container {
    width: 100% !important;
  }
}
@media only screen and (min-width: 1540px) {
  body.has-toolbar.has-sidebar-collapsed #page-document.ui.container {
    width: 921px !important;
  }
}
@media only screen and (min-width: 1540px) {
  body.has-toolbar.has-sidebar-collapsed .full-width {
    position: static;
    width: initial !important;
  }
  body.has-toolbar.has-sidebar-collapsed .fix-width-issue {
    width: 1351px;
  }
  body.has-toolbar.has-sidebar-collapsed .block.hero,
  body.has-toolbar.has-sidebar-collapsed .job-filter-block,
  body.has-toolbar.has-sidebar-collapsed .benefits,
  body.has-toolbar.has-sidebar-collapsed .block.maps.align.full,
  body.has-toolbar.has-sidebar-collapsed .block.video.align.full,
  body.has-toolbar.has-sidebar-collapsed .block.header-wrapper,
  body.has-toolbar.has-sidebar-collapsed .block.aktuelles,
  body.has-toolbar.has-sidebar-collapsed .block.meldungen,
  body.has-toolbar.has-sidebar-collapsed .block.image {
    display: flex;
    width: 100% !important;
    justify-content: center;
  }
  body.has-toolbar.has-sidebar-collapsed .block.hero .full-width,
  body.has-toolbar.has-sidebar-collapsed .job-filter-block .full-width,
  body.has-toolbar.has-sidebar-collapsed .benefits .full-width,
  body.has-toolbar.has-sidebar-collapsed .block.maps.align.full .full-width,
  body.has-toolbar.has-sidebar-collapsed .block.video.align.full .full-width,
  body.has-toolbar.has-sidebar-collapsed .block.header-wrapper .full-width,
  body.has-toolbar.has-sidebar-collapsed .block.aktuelles .full-width,
  body.has-toolbar.has-sidebar-collapsed .block.meldungen .full-width,
  body.has-toolbar.has-sidebar-collapsed .block.image .full-width {
    width: 1351px !important;
  }
}
body.has-toolbar-collapsed:not(.has-sidebar):not(.has-sidebar-collapsed) {
  /* Disable full-width at 1440 */
}
@media only screen and (max-width: 1459px) {
  body.has-toolbar-collapsed:not(.has-sidebar):not(.has-sidebar-collapsed) {
    /* Main frame fluid up to 1440 */
  }
  body.has-toolbar-collapsed:not(.has-sidebar):not(.has-sidebar-collapsed) .header-wrapper .ui.container {
    width: 100% !important;
  }
}
@media only screen and (min-width: 1460px) {
  body.has-toolbar-collapsed:not(.has-sidebar):not(.has-sidebar-collapsed) #page-document.ui.container {
    width: 921px !important;
  }
}
@media only screen and (min-width: 1460px) {
  body.has-toolbar-collapsed:not(.has-sidebar):not(.has-sidebar-collapsed) .full-width {
    position: static;
    width: initial !important;
  }
  body.has-toolbar-collapsed:not(.has-sidebar):not(.has-sidebar-collapsed) .fix-width-issue {
    width: 1351px;
  }
  body.has-toolbar-collapsed:not(.has-sidebar):not(.has-sidebar-collapsed) .block.hero,
  body.has-toolbar-collapsed:not(.has-sidebar):not(.has-sidebar-collapsed) .job-filter-block,
  body.has-toolbar-collapsed:not(.has-sidebar):not(.has-sidebar-collapsed) .benefits,
  body.has-toolbar-collapsed:not(.has-sidebar):not(.has-sidebar-collapsed) .block.maps.align.full,
  body.has-toolbar-collapsed:not(.has-sidebar):not(.has-sidebar-collapsed) .block.video.align.full,
  body.has-toolbar-collapsed:not(.has-sidebar):not(.has-sidebar-collapsed) .block.header-wrapper,
  body.has-toolbar-collapsed:not(.has-sidebar):not(.has-sidebar-collapsed) .block.aktuelles,
  body.has-toolbar-collapsed:not(.has-sidebar):not(.has-sidebar-collapsed) .block.meldungen,
  body.has-toolbar-collapsed:not(.has-sidebar):not(.has-sidebar-collapsed) .block.image {
    display: flex;
    width: 100% !important;
    justify-content: center;
  }
  body.has-toolbar-collapsed:not(.has-sidebar):not(.has-sidebar-collapsed) .block.hero .full-width,
  body.has-toolbar-collapsed:not(.has-sidebar):not(.has-sidebar-collapsed) .job-filter-block .full-width,
  body.has-toolbar-collapsed:not(.has-sidebar):not(.has-sidebar-collapsed) .benefits .full-width,
  body.has-toolbar-collapsed:not(.has-sidebar):not(.has-sidebar-collapsed) .block.maps.align.full .full-width,
  body.has-toolbar-collapsed:not(.has-sidebar):not(.has-sidebar-collapsed) .block.video.align.full .full-width,
  body.has-toolbar-collapsed:not(.has-sidebar):not(.has-sidebar-collapsed) .block.header-wrapper .full-width,
  body.has-toolbar-collapsed:not(.has-sidebar):not(.has-sidebar-collapsed) .block.aktuelles .full-width,
  body.has-toolbar-collapsed:not(.has-sidebar):not(.has-sidebar-collapsed) .block.meldungen .full-width,
  body.has-toolbar-collapsed:not(.has-sidebar):not(.has-sidebar-collapsed) .block.image .full-width {
    width: 1351px !important;
  }
}
body.has-toolbar-collapsed.has-sidebar {
  /* Disable full-width at 1440 */
}
@media only screen and (max-width: 1834px) {
  body.has-toolbar-collapsed.has-sidebar {
    /* Main frame fluid up to 1440 */
  }
  body.has-toolbar-collapsed.has-sidebar .header-wrapper .ui.container {
    width: 100% !important;
  }
}
@media only screen and (min-width: 1835px) {
  body.has-toolbar-collapsed.has-sidebar #page-document.ui.container {
    width: 921px !important;
  }
}
@media only screen and (min-width: 1835px) {
  body.has-toolbar-collapsed.has-sidebar .full-width {
    position: static;
    width: initial !important;
  }
  body.has-toolbar-collapsed.has-sidebar .fix-width-issue {
    width: 1351px;
  }
  body.has-toolbar-collapsed.has-sidebar .block.hero,
  body.has-toolbar-collapsed.has-sidebar .job-filter-block,
  body.has-toolbar-collapsed.has-sidebar .benefits,
  body.has-toolbar-collapsed.has-sidebar .block.maps.align.full,
  body.has-toolbar-collapsed.has-sidebar .block.video.align.full,
  body.has-toolbar-collapsed.has-sidebar .block.header-wrapper,
  body.has-toolbar-collapsed.has-sidebar .block.aktuelles,
  body.has-toolbar-collapsed.has-sidebar .block.meldungen,
  body.has-toolbar-collapsed.has-sidebar .block.image {
    display: flex;
    width: 100% !important;
    justify-content: center;
  }
  body.has-toolbar-collapsed.has-sidebar .block.hero .full-width,
  body.has-toolbar-collapsed.has-sidebar .job-filter-block .full-width,
  body.has-toolbar-collapsed.has-sidebar .benefits .full-width,
  body.has-toolbar-collapsed.has-sidebar .block.maps.align.full .full-width,
  body.has-toolbar-collapsed.has-sidebar .block.video.align.full .full-width,
  body.has-toolbar-collapsed.has-sidebar .block.header-wrapper .full-width,
  body.has-toolbar-collapsed.has-sidebar .block.aktuelles .full-width,
  body.has-toolbar-collapsed.has-sidebar .block.meldungen .full-width,
  body.has-toolbar-collapsed.has-sidebar .block.image .full-width {
    width: 1351px !important;
  }
}
body.has-toolbar-collapsed.has-sidebar-collapsed {
  /* Disable full-width at 1440 */
}
@media only screen and (max-width: 1479px) {
  body.has-toolbar-collapsed.has-sidebar-collapsed {
    /* Main frame fluid up to 1440 */
  }
  body.has-toolbar-collapsed.has-sidebar-collapsed .header-wrapper .ui.container {
    width: 100% !important;
  }
}
@media only screen and (min-width: 1480px) {
  body.has-toolbar-collapsed.has-sidebar-collapsed #page-document.ui.container {
    width: 921px !important;
  }
}
@media only screen and (min-width: 1480px) {
  body.has-toolbar-collapsed.has-sidebar-collapsed .full-width {
    position: static;
    width: initial !important;
  }
  body.has-toolbar-collapsed.has-sidebar-collapsed .fix-width-issue {
    width: 1351px;
  }
  body.has-toolbar-collapsed.has-sidebar-collapsed .block.hero,
  body.has-toolbar-collapsed.has-sidebar-collapsed .job-filter-block,
  body.has-toolbar-collapsed.has-sidebar-collapsed .benefits,
  body.has-toolbar-collapsed.has-sidebar-collapsed .block.maps.align.full,
  body.has-toolbar-collapsed.has-sidebar-collapsed .block.video.align.full,
  body.has-toolbar-collapsed.has-sidebar-collapsed .block.header-wrapper,
  body.has-toolbar-collapsed.has-sidebar-collapsed .block.aktuelles,
  body.has-toolbar-collapsed.has-sidebar-collapsed .block.meldungen,
  body.has-toolbar-collapsed.has-sidebar-collapsed .block.image {
    display: flex;
    width: 100% !important;
    justify-content: center;
  }
  body.has-toolbar-collapsed.has-sidebar-collapsed .block.hero .full-width,
  body.has-toolbar-collapsed.has-sidebar-collapsed .job-filter-block .full-width,
  body.has-toolbar-collapsed.has-sidebar-collapsed .benefits .full-width,
  body.has-toolbar-collapsed.has-sidebar-collapsed .block.maps.align.full .full-width,
  body.has-toolbar-collapsed.has-sidebar-collapsed .block.video.align.full .full-width,
  body.has-toolbar-collapsed.has-sidebar-collapsed .block.header-wrapper .full-width,
  body.has-toolbar-collapsed.has-sidebar-collapsed .block.aktuelles .full-width,
  body.has-toolbar-collapsed.has-sidebar-collapsed .block.meldungen .full-width,
  body.has-toolbar-collapsed.has-sidebar-collapsed .block.image .full-width {
    width: 1351px !important;
  }
}
@font-face {
  font-family: 'FuturaPT-Book';
  src: url('../../../../../../../theme/assets/fonts/FuturaPT-Book.eot');
  src: url('../../../../../../../theme/assets/fonts/FuturaPT-Book.eot?#iefix') format('embedded-opentype'), url('../../../../../../../theme/assets/fonts/FuturaPT-Book.woff2') format('woff2'), url('../../../../../../../theme/assets/fonts/FuturaPT-Book.woff') format('woff'), url('../../../../../../../theme/assets/fonts/FuturaPT-Book.ttf') format('truetype');
}
@font-face {
  font-family: 'FuturaPT-Bold';
  src: url('../../../../../../../theme/assets/fonts/FuturaPT-Bold.eot');
  src: url('../../../../../../../theme/assets/fonts/FuturaPT-Bold.eot?#iefix') format('embedded-opentype'), url('../../../../../../../theme/assets/fonts/FuturaPT-Bold.woff2') format('woff2'), url('../../../../../../../theme/assets/fonts/FuturaPT-Bold.woff') format('woff'), url('../../../../../../../theme/assets/fonts/FuturaPT-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'FuturaPT-BookOblique';
  src: url('../../../../../../../theme/assets/fonts/FuturaPT-BookObl.eot');
  src: url('../../../../../../../theme/assets/fonts/FuturaPT-BookObl.eot?#iefix') format('embedded-opentype'), url('../../../../../../../theme/assets/fonts/FuturaPT-BookObl.woff2') format('woff2'), url('../../../../../../../theme/assets/fonts/FuturaPT-BookObl.woff') format('woff'), url('../../../../../../../theme/assets/fonts/FuturaPT-BookObl.ttf') format('truetype');
}
@font-face {
  font-family: 'TalkeIcons';
  src: url('../../../../../../../theme/assets/fonts/talke-icons.eot');
  src: url('../../../../../../../theme/assets/fonts/talke-icons.woff') format('woff'), url('../../../../../../../theme/assets/fonts/talke-icons.ttf') format('truetype');
}
.block.imagesGrid .ui.grid,
.block.teaserGrid .ui.grid,
.block.grid .ui.grid {
  margin-top: 0;
  margin-bottom: 0;
}
.block.imagesGrid .column,
.block.teaserGrid .column,
.block.grid .column {
  z-index: 5;
}
.ui.modal.block {
  top: initial;
  left: initial;
  margin-right: auto;
  margin-left: auto;
}
.ui.modal.block .header {
  display: flex;
  justify-content: space-between;
}
.ui.modal.block .header .icon {
  cursor: pointer;
}
.ui.modal.block .content .wrapper {
  border-bottom: 1px solid #edf1f2;
}
.ui.modal.block .content .wrapper label {
  font-weight: bold;
  text-transform: uppercase;
}
.ui.modal.block .content .ui.grid {
  margin: 0;
}
.ui.modal.block .content .field {
  margin-bottom: 10px;
}
.ui.modal.block .content .field-group-wrapper {
  border-bottom: 1px solid #c7d5d8;
}
.block .block.detached:hover::after {
  border: none;
}
.block .block.detached:not(.inner)::after {
  display: none;
}
.block .block-tooltip {
  position: absolute;
  z-index: 5;
  top: -22px;
  display: none;
  padding: 2px 7px;
  background-color: #252525;
  border-radius: 2px;
  color: #f2f5f6;
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
}
.block.selected .block-tooltip,
.block:hover .block-tooltip {
  display: block;
}
.ui.accordion .ui.segment {
  top: 0;
  bottom: 0;
  width: auto;
  border: none;
  border-top: none;
  border-bottom: 1px solid #b8c6c8;
  margin: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
/* This might be required to standardize */
.teaser-item,
.grid-teaser-item,
.grid-image-item {
  display: flex;
  flex-direction: column;
}
.teaser-item .grid-image-wrapper,
.grid-teaser-item .grid-image-wrapper,
.grid-image-item .grid-image-wrapper {
  display: flex;
  align-items: flex-start;
}
.teaser-item img,
.grid-teaser-item img,
.grid-image-item img {
  width: 100%;
  object-fit: cover;
  object-position: top left;
}
.teaser-item a,
.grid-teaser-item a,
.grid-image-item a {
  color: rgba(0, 0, 0, 0.87);
}
.teaser-item p,
.grid-teaser-item p,
.grid-image-item p {
  margin-bottom: 0;
  text-align: left;
}
.teaser-item.default,
.grid-teaser-item.default,
.grid-image-item.default {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.teaser-item.default img,
.grid-teaser-item.default img,
.grid-image-item.default img {
  max-width: 300px;
  align-self: center;
}
.teaser-item a,
.grid-teaser-item a,
.grid-image-item a {
  display: flex;
}
.teaser-item.top .grid-image-wrapper,
.grid-teaser-item.top .grid-image-wrapper,
.grid-image-item.top .grid-image-wrapper {
  margin-bottom: 25px;
}
.teaser-item.top a,
.grid-teaser-item.top a,
.grid-image-item.top a {
  display: flex;
  flex-direction: column;
}
.teaser-item.left a,
.grid-teaser-item.left a,
.grid-image-item.left a {
  flex-direction: row;
}
.teaser-item.left a .grid-image-wrapper,
.grid-teaser-item.left a .grid-image-wrapper,
.grid-image-item.left a .grid-image-wrapper {
  width: 49%;
  flex: 1 0 auto;
}
@media only screen and (max-width: 767px) {
  .teaser-item.left a .grid-image-wrapper,
  .grid-teaser-item.left a .grid-image-wrapper,
  .grid-image-item.left a .grid-image-wrapper {
    width: 100%;
  }
}
.teaser-item.left a h3,
.grid-teaser-item.left a h3,
.grid-image-item.left a h3,
.teaser-item.left a p,
.grid-teaser-item.left a p,
.grid-image-item.left a p {
  padding-left: 20px;
}
@media only screen and (max-width: 767px) {
  .teaser-item.left a h3,
  .grid-teaser-item.left a h3,
  .grid-image-item.left a h3,
  .teaser-item.left a p,
  .grid-teaser-item.left a p,
  .grid-image-item.left a p {
    padding-left: 0;
  }
}
@media only screen and (max-width: 767px) {
  .teaser-item.left a,
  .grid-teaser-item.left a,
  .grid-image-item.left a {
    flex-direction: column;
  }
  .teaser-item.left a img,
  .grid-teaser-item.left a img,
  .grid-image-item.left a img {
    width: 100%;
    margin-bottom: 25px;
  }
}
.teaser-item.right a,
.grid-teaser-item.right a,
.grid-image-item.right a {
  flex-direction: row-reverse;
  justify-content: space-between;
}
.teaser-item.right a .grid-image-wrapper,
.grid-teaser-item.right a .grid-image-wrapper,
.grid-image-item.right a .grid-image-wrapper {
  width: 49%;
  flex: 1 0 auto;
}
.teaser-item.right a h3,
.grid-teaser-item.right a h3,
.grid-image-item.right a h3,
.teaser-item.right a p,
.grid-teaser-item.right a p,
.grid-image-item.right a p {
  padding-right: 20px;
}
@media only screen and (max-width: 767px) {
  .teaser-item.right a,
  .grid-teaser-item.right a,
  .grid-image-item.right a {
    flex-direction: column;
  }
  .teaser-item.right a img,
  .grid-teaser-item.right a img,
  .grid-image-item.right a img {
    width: 100%;
    margin-bottom: 25px;
  }
}
.teaser-item.padded,
.grid-teaser-item.padded,
.grid-image-item.padded {
  width: 80%;
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  .teaser-item.padded,
  .grid-teaser-item.padded,
  .grid-image-item.padded {
    width: 100%;
  }
}
.block.inner.teaserHero .teaser-item.left {
  flex-direction: row;
}
.block.inner.teaserHero .teaser-item.left .grid-image-wrapper {
  width: 49%;
  flex: 1 0 auto;
  margin-right: 20px;
}
.block.inner.teaserHero .teaser-item.right {
  flex-direction: row-reverse;
  justify-content: space-between;
}
.block.inner.teaserHero .teaser-item.right .grid-image-wrapper {
  width: 49%;
  flex: 1 0 auto;
  margin-left: 20px;
}
.accordion-tools {
  display: flex;
  align-items: center;
}
.accordion-tools .ui.basic.button {
  margin-right: 10px;
}
.accordion-tools .ui.basic.button:hover {
  background-color: transparent !important;
}
.sidebar-listing-data.ui.form.segment {
  padding-bottom: 20px;
}
.sidebar-listing-data .simple-field-name {
  padding-top: 20px;
  padding-bottom: 10px;
  font-weight: 500;
}
.sidebar-listing-data .fields {
  flex-wrap: wrap;
  padding-bottom: 20px;
  border-bottom: 1px dotted #C7D5D8;
}
.sidebar-listing-data .fields:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.sidebar-listing-data .main-fields-wrapper {
  display: flex;
  width: 100%;
  flex: 1 0 auto;
  padding-right: 0.5em;
  padding-left: 0.5em;
}
.sidebar-listing-data .button .icon {
  margin: 0 !important;
}
.sidebar-teaser-data .data-items-listing {
  justify-content: space-between;
}
.ui.button.template-chooser-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 0 20px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}
.ui.button.template-chooser-item .template-chooser-title {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.blocks-chooser .ui.basic.button {
  text-align: center;
}
.block:not(.text) {
  clear: both;
}
.data-items-listing {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  margin: 10px;
  background-color: #eee;
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
}
.data-items-listing .icon {
  flex: 1 0 auto;
  margin-left: 10px !important;
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
/* Slider */
.slick-loading .slick-list {
  background: #fff url('../../../../../../slick-carousel/slick/ajax-loader.gif') center center no-repeat;
}
/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url('../../../../../../slick-carousel/slick/fonts/slick.eot');
  src: url('../../../../../../slick-carousel/slick/fonts/slick.eot?#iefix') format('embedded-opentype'), url('../../../../../../slick-carousel/slick/fonts/slick.woff') format('woff'), url('../../../../../../slick-carousel/slick/fonts/slick.ttf') format('truetype'), url('../../../../../../slick-carousel/slick/fonts/slick.svg#slick') format('svg');
}
/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev {
  left: -25px;
}
[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto;
}
.slick-prev:before {
  content: '←';
}
[dir='rtl'] .slick-prev:before {
  content: '→';
}
.slick-next {
  right: -25px;
}
[dir='rtl'] .slick-next {
  right: auto;
  left: -25px;
}
.slick-next:before {
  content: '→';
}
[dir='rtl'] .slick-next:before {
  content: '←';
}
/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}
/* Basics */
body {
  background: #FFFFFF;
  color: #264963;
}
.block > * {
  background: inherit;
}
.block,
.ui.grid {
  margin-top: 0;
  margin-bottom: 0;
}
.view-editview .block {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.ui.button.fluid.circular.joblisting {
  height: inherit;
  min-height: 1em;
  padding: 0.61111111em 1.5em 0.61111111em 1.5rem;
  font-size: 1rem;
  line-height: 1em;
}
h1,
h2 {
  font-family: 'FuturaPT-Bold';
  text-transform: uppercase;
}
p:empty {
  min-height: 19px;
}
*.color.red {
  color: #E20026;
}
#main {
  height: fit-content;
}
/* Sidebar */
.sidebar-container {
  color: #262626;
}
.sidebar-container .background-color-chooser .ui.button {
  width: 25px;
  height: 25px;
  padding: 0;
  margin: 0 10px;
}
.sidebar-container .background-color-chooser .ui.button.white {
  border: 1px #262626 solid;
  background: #FFFFFF;
}
.sidebar-container .background-color-chooser .ui.button.blue {
  background: linear-gradient(90deg, #0a2576 0%, #3890d2 100%);
}
.sidebar-container .background-color-chooser .ui.button.grey {
  background: linear-gradient(to left, #fff 0%, #b3b3b3 100%);
}
.sidebar-container .background-color-chooser .ui.button.selected {
  border: 2px #007EB1 solid;
}
/* Header */
.main-header {
  position: sticky;
  z-index: 999;
}
@media only screen and (max-width: 768px) {
  .main-header {
    position: fixed;
    z-index: 100;
    width: 100%;
    background: #FFFFFF;
  }
}
.main-header .ui.basic.segment.header-wrapper {
  padding: 0;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 29px;
}
@media only screen and (max-width: 768px) {
  .main-header .ui.basic.segment.header-wrapper .ui.container {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}
.main-header .ui.basic.segment.header-wrapper .header {
  height: 75px;
  flex-direction: row;
  align-items: center;
}
@media only screen and (min-width: 768px) {
  .main-header .ui.basic.segment.header-wrapper .header {
    padding: 0 18px;
  }
}
@media only screen and (max-width: 768px) {
  .main-header .ui.basic.segment.header-wrapper .header {
    height: 48px;
    box-shadow: 0 3px 6px rgba(0, 0, 41, 0.6);
  }
}
@media only screen and (max-width: 768px) {
  .main-header .ui.basic.segment.header-wrapper .header .logo-nav-wrapper {
    align-items: center;
    justify-content: space-between;
    margin-right: 20px;
    margin-left: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .main-header .ui.basic.segment.header-wrapper .header .logo-nav-wrapper .logo img {
    height: 22px;
  }
}
.main-header .ui.basic.segment.header-wrapper .header .logo-nav-wrapper .hamburger-button {
  display: none;
  border: none;
  background: transparent;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .main-header .ui.basic.segment.header-wrapper .header .logo-nav-wrapper .hamburger-button {
    display: block;
  }
}
.main-header .ui.basic.segment.header-wrapper .header .language-selector {
  color: #264963;
}
@media only screen and (max-width: 768px) {
  .main-header .ui.basic.segment.header-wrapper .header .language-selector {
    display: none;
  }
}
.main-header .ui.basic.segment.header-wrapper .header .language-selector a {
  color: #264963;
}
.main-header .ui.basic.segment.header-wrapper .header .language-selector a.selected {
  text-decoration: underline;
}
.main-header .ui.basic.segment.header-wrapper .header .ui.button.primary {
  padding: 10px 22px;
  margin-right: 0;
  margin-left: 36px;
  background: #E20026;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
}
@media only screen and (max-width: 992px) {
  .main-header .ui.basic.segment.header-wrapper .header .ui.button.primary {
    display: none;
  }
}
.main-header .header-placeholder {
  display: none;
  height: 39px;
}
.main-header .header-placeholder.visible {
  display: block;
}
.ui.basic.segment.sub-navigation {
  padding: 0;
  margin-top: 0;
  background: #EEE;
  box-shadow: inset 0px 2px 5px -3px rgba(0, 0, 0, 0.75);
}
.ui.basic.segment.sub-navigation.static {
  position: sticky !important;
  z-index: 50;
  top: 0;
  width: 100%;
}
@media only screen and (max-width: 992px) {
  .ui.basic.segment.sub-navigation .ui.container {
    margin: 0 !important;
  }
}
.ui.basic.segment.sub-navigation .ui.secondary.pointing.menu {
  position: relative;
  min-height: initial;
  padding: 0 1rem;
  margin: 0;
}
.ui.basic.segment.sub-navigation .ui.secondary.pointing.menu .nav-item-wrapper {
  z-index: 999;
  display: flex;
  padding: 8px 0;
}
.ui.basic.segment.sub-navigation .ui.secondary.pointing.menu .nav-item-wrapper:hover .submenu-wrapper {
  display: block;
}
.ui.basic.segment.sub-navigation .ui.secondary.pointing.menu .nav-item-wrapper .submenu-wrapper {
  position: absolute;
  z-index: 999;
  top: 39px;
  left: 0;
  display: none;
  width: 100%;
  padding-left: 0;
  margin-top: 0;
  background: #FFFFFF;
}
.ui.basic.segment.sub-navigation .ui.secondary.pointing.menu .nav-item-wrapper .submenu-wrapper.active {
  display: block;
}
.ui.basic.segment.sub-navigation .ui.secondary.pointing.menu .nav-item-wrapper .submenu-wrapper ul {
  display: flex;
  padding-left: 1rem;
  padding-left: 0;
  margin: 0;
  list-style: none;
}
.ui.basic.segment.sub-navigation .ui.secondary.pointing.menu .nav-item-wrapper .submenu-wrapper ul li {
  background: #FFFFFF;
}
.ui.basic.segment.sub-navigation .ui.secondary.pointing.menu .nav-item-wrapper .submenu-wrapper ul li a {
  padding: 1rem;
  margin: 0;
}
.ui.basic.segment.sub-navigation .ui.secondary.pointing.menu .nav-item-wrapper .submenu-wrapper ul li a:hover {
  background: #EEE;
}
.ui.basic.segment.sub-navigation .ui.secondary.pointing.menu .item {
  padding: 0;
  border-bottom: none;
  color: #264963;
  font-size: 16px;
  line-height: 23px;
  text-transform: none;
}
.ui.basic.segment.sub-navigation .ui.secondary.pointing.menu .item.active {
  border-bottom: none;
  font-family: 'FuturaPT-Bold';
}
/* Mobile Navigation */
.mobile.navigation {
  display: none;
}
.mobile.navigation.open {
  display: block;
}
.mobile.navigation .ui.pointing.menu {
  position: fixed;
  z-index: 101;
  top: 0;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  padding: 40px 36px;
  background: linear-gradient(#3890d2 0%, #0a2576 100%);
  color: #FFFFFF;
  overflow-y: scroll;
}
.mobile.navigation .ui.pointing.menu .nav-item-wrapper {
  align-items: center;
}
.mobile.navigation .ui.pointing.menu .nav-item-wrapper > div {
  display: flex;
}
.mobile.navigation .ui.pointing.menu .nav-item-wrapper.last {
  padding-bottom: 50px;
}
.mobile.navigation .ui.pointing.menu .nav-item-wrapper button {
  border: none;
  background: transparent;
  color: #FFFFFF;
}
.mobile.navigation .ui.pointing.menu .nav-item-wrapper button i {
  font-size: 1.5rem;
}
.mobile.navigation .ui.pointing.menu .nav-item-wrapper.submenu-open i {
  transform: rotate(90deg);
}
.mobile.navigation .ui.pointing.menu .nav-item-wrapper .submenu-wrapper {
  z-index: 100;
  left: 0;
  display: none;
  width: 100vw;
}
.mobile.navigation .ui.pointing.menu .nav-item-wrapper .submenu-wrapper button {
  padding-left: 20px;
  text-transform: uppercase;
}
.mobile.navigation .ui.pointing.menu .nav-item-wrapper .submenu-wrapper button i {
  position: relative;
  top: 4px;
  margin-right: 10px;
}
.mobile.navigation .ui.pointing.menu .nav-item-wrapper .submenu-wrapper.open {
  display: block;
}
.mobile.navigation .ui.pointing.menu .nav-item-wrapper .submenu-wrapper ul {
  padding-left: 1rem;
  margin-top: 1rem;
  list-style: none;
}
.mobile.navigation .ui.pointing.menu .nav-item-wrapper .submenu-wrapper ul li {
  padding: 12px 0;
}
.mobile.navigation .ui.pointing.menu .nav-item-wrapper .submenu-wrapper ul li .item {
  margin: 0;
}
.mobile.navigation .ui.pointing.menu a {
  border: none;
  color: #FFFFFF;
}
.mobile.navigation .ui.pointing.menu .tools-wrapper.mobile.only {
  display: flex;
  justify-content: flex-end;
}
.mobile.navigation .ui.pointing.menu .tools-wrapper.mobile.only .language-selector {
  display: block;
  margin-right: 25px;
  color: #FFFFFF;
  font-size: 20px;
  line-height: 25px;
}
.mobile.navigation .ui.pointing.menu .tools-wrapper.mobile.only .language-selector a.selected {
  text-decoration: underline;
}
.mobile.navigation .ui.pointing.menu .tools-wrapper.mobile.only svg {
  cursor: pointer;
}
.mobile.navigation .ui.pointing.menu h2 {
  margin-bottom: 50px;
  font-family: 'FuturaPT-Bold';
  font-size: 25px;
  line-height: 31px;
  text-transform: uppercase;
}
.mobile.navigation .ui.pointing.menu a.item {
  padding: 0;
  margin: 12px 0;
  text-transform: uppercase;
}
.mobile.navigation .ui.pointing.menu a.item.active {
  color: #FFFFFF;
  font-family: 'FuturaPT-Bold';
}
/* Footer */
.ui.segment.footer .container {
  display: flex;
  height: 185px;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-around;
}
@media only screen and (max-width: 992px) {
  .ui.segment.footer .container {
    height: initial;
    padding-bottom: 50px;
  }
}
.ui.segment.footer .container .ui.list {
  display: flex;
  align-content: center;
  justify-content: space-around;
}
@media only screen and (max-width: 992px) {
  .ui.segment.footer .container .ui.list {
    justify-content: center;
    margin-top: 25px;
  }
}
.ui.segment.footer .container .ui.list a.item {
  padding: 0;
  color: #264963;
  font-size: 25px;
  line-height: 36px;
}
.ui.segment.footer .container .ui.list a.item svg {
  height: 30px;
}
@media only screen and (max-width: 992px) {
  .ui.segment.footer .container .ui.list a.item svg {
    height: 25px;
  }
}
@media only screen and (max-width: 992px) {
  .ui.segment.footer .container .ui.list.footer-text a {
    font-size: 18px;
  }
}
.ui.segment.footer .container .ui.list.footer-icons,
.ui.segment.footer .container .ui.list.footer-text {
  width: 40%;
}
@media only screen and (max-width: 992px) {
  .ui.segment.footer .container .ui.list.footer-icons,
  .ui.segment.footer .container .ui.list.footer-text {
    width: 100%;
    padding: 0 35px;
  }
}
@media only screen and (max-width: 992px) {
  .ui.segment.footer .container .ui.list.footer-text {
    justify-content: space-around;
  }
}
.ui.segment.footer .container .ui.list.footer-logo {
  width: 20%;
}
@media only screen and (max-width: 992px) {
  .ui.segment.footer .container .ui.list.footer-logo {
    width: 100%;
  }
}
@media only screen and (max-width: 992px) {
  .ui.segment.footer .container .ui.list.footer-logo .ui.image {
    position: initial;
    height: 29px;
  }
}
/* Content */
.documentFirstHeading::before {
  display: none;
}
.ui.basic.segment.content-area {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}
@media only screen and (max-width: 768px) {
  .ui.basic.segment.content-area {
    margin-top: 48px;
  }
}
@media only screen and (min-width: 768px) {
  .ui.basic.segment.content-area.third-layer-page {
    margin-top: 60px;
  }
}
/* Hero Block */
.block.hero {
  background: #FFFFFF;
  /* Contact */
  /* Job Offers */
  /* Double Text*/
}
.block.hero h2 {
  font: 25px/31px 'FuturaPT-Bold';
}
.block.hero .block-inner-wrapper {
  min-height: 675px;
}
@media only screen and (max-width: 1440px) {
  .block.hero .block-inner-wrapper {
    min-height: 50vw;
  }
}
.block.hero .block-inner-wrapper > h2,
.block.hero .block-inner-wrapper > h1 {
  display: none;
  text-transform: uppercase;
}
.block.hero.title h1 {
  font: 60px/65px 'FuturaPT-Bold';
}
.block.hero.title .block-inner-wrapper {
  overflow-y: hidden;
}
.block.hero.blue {
  background: linear-gradient(to left, #3890d2 0%, #0a2576 100%);
  color: #FFFFFF;
}
.block.hero.grey {
  background: linear-gradient(to left, #fff 0%, #b3b3b3 100%);
}
.block.hero .hero-image-container {
  width: 50%;
}
.block.hero .hero-image-container.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.block.hero .hero-image-container.icon .hero-image {
  max-width: 33%;
  max-height: 33%;
  object-fit: scale-down;
}
.block.hero .hero-image-container .hero-image {
  overflow: visible;
  width: 100%;
  max-width: initial;
  min-height: 100%;
  max-height: none;
  object-fit: cover;
}
.block.hero .hero-image-container .hero-image .ui.message {
  margin-bottom: 0;
}
.block.hero .hero-image-container .hero-image center .toolbar-inner {
  width: 100%;
  min-width: inherit;
}
.block.hero .dropzone {
  height: 100%;
}
.block.hero .dropzone .ui.message {
  height: 100%;
}
.block.hero .hero-body {
  overflow: visible;
  width: 50%;
  max-width: 450px;
  max-height: none;
  align-self: center;
  padding: 0;
  margin: 0 auto;
  background: transparent;
}
.block.hero .hero-body p {
  max-height: none;
}
.block.hero .hero-body ul {
  padding-left: 1rem;
}
.block.hero .hero-body ul li::marker {
  color: #E20026;
}
.block.hero .hero-body .ui.button.fluid.circular {
  border: #264963 2px solid;
  margin-top: 30px;
  background: #FFFFFF;
  color: #264963;
  font-weight: normal;
}
.block.hero .hero-body .ui.button.fluid.circular.primary {
  border-color: #E20026;
  background: #E20026;
  color: #FFFFFF;
}
@media only screen and (max-width: 992px) {
  .block.hero {
    margin-bottom: 0;
  }
  .block.hero .block-inner-wrapper.full-width {
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    padding: 1rem 35px;
  }
  .block.hero .block-inner-wrapper.full-width > h2 {
    display: inline;
    margin-bottom: 0;
    font: 18pt/29pt 'FuturaPT-Bold';
  }
  .block.hero .block-inner-wrapper.full-width .hero-image-container {
    width: 100%;
    height: 100%;
  }
  .block.hero .block-inner-wrapper.full-width .hero-image-container img.hero-image {
    min-height: 0;
    object-fit: contain;
    object-position: left;
  }
  .block.hero .block-inner-wrapper.full-width .hero-body {
    width: 100%;
    max-width: none;
    height: 100%;
    flex: auto;
    padding-top: 1rem;
  }
  .block.hero.title {
    position: relative;
    height: calc(100vh - 114px);
    min-height: 650px;
    background: none;
  }
  .block.hero.title .block-inner-wrapper.full-width {
    min-height: 0;
    padding: 0;
    color: #FFFFFF;
  }
  .block.hero.title .block-inner-wrapper.full-width h2 {
    display: none;
  }
  .block.hero.title .block-inner-wrapper.full-width .hero-image-container {
    position: absolute;
    z-index: -1;
    min-height: 100%;
  }
  .block.hero.title .block-inner-wrapper.full-width .hero-image-container .hero-image {
    position: relative;
    bottom: 0;
    max-width: none;
    min-height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .block.hero.title .block-inner-wrapper.full-width .hero-body {
    z-index: 10;
    bottom: 0;
    display: flex;
    overflow: hidden;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px 35px;
    background: linear-gradient(#6e6e6e00 0%, #071334 100%);
  }
  .block.hero.title .block-inner-wrapper.full-width .hero-body a.ui.button.primary {
    font-size: 16pt;
  }
  .block.hero.title .block-inner-wrapper.full-width .hero-body h1 {
    font: 30pt/43pt 'FuturaPT-Bold';
  }
}
@media only screen and (max-width: 767px) {
  .block.hero .hero-image-container.icon {
    margin: 20px 0 20px 0;
  }
}
@media only screen and (max-width: 768px) {
  .block.hero.title {
    height: calc(100vh - 48px);
  }
}
.block.hero.contact {
  background: linear-gradient(#fff 0%, #b3b3b3 100%);
}
@media only screen and (max-width: 992px) {
  .block.hero.contact .hero-body {
    max-width: 250px;
  }
}
.block.hero.contact a {
  color: #264963;
  text-decoration: underline;
}
.block.hero.contact .hero-body > * {
  margin: 21px auto;
}
.block.hero.contact.static .block-inner-wrapper {
  min-height: initial;
}
.block.hero.contact.static .block-inner-wrapper .hero-image-container .hero-image {
  object-fit: contain;
}
.block.hero.job-offers.blue .job-offer-unit a {
  color: #FFFFFF;
}
.block.hero.job-offers h2 {
  font-family: 'FuturaPT-Bold';
}
.block.hero.job-offers ul {
  padding-left: 0;
  list-style: none;
}
.block.hero.job-offers .job-offer-unit {
  width: 100%;
  border-bottom: 1px solid #999;
  margin-bottom: 1rem;
  line-height: 1rem;
}
.block.hero.job-offers .job-offer-unit a {
  color: #264963;
  text-decoration: underline;
}
.block.hero.job-offers .job-offer-unit p {
  color: #999;
}
@media only screen and (max-width: 992px) {
  .block.hero.job-offers .hero-body > p {
    font-size: 16pt;
    line-height: 24pt;
    text-transform: uppercase;
  }
  .block.hero.job-offers .job-offer-unit a {
    font-size: 16pt;
    line-height: 24pt;
    text-transform: uppercase;
  }
}
.block.hero.double-text .block-inner-wrapper {
  min-height: 300px;
}
.block.hero.double-text .hero-body {
  align-self: flex-start;
  margin-top: 2rem;
}
/* Job Filter */
.job-filter-block {
  padding: 0 1rem;
  color: #FFFFFF;
}
.job-filter-block .ui.grid.full-width {
  background: linear-gradient(to left, #3890d2 0%, #0a2576 100%);
}
.job-filter-block .job {
  display: flex;
  min-height: 332px;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  margin-bottom: 1rem;
  background: linear-gradient(180deg, #112472, #182553 100%);
  text-align: left;
}
.job-filter-block .job h2 {
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
}
.job-filter-block .job p {
  color: white;
  font-size: 1rem;
}
.job-filter-block .job .ui.button {
  display: block;
  width: 200px;
  margin: 0 auto;
  color: white;
  font-weight: 700;
  line-height: 38px;
}
.job-filter-block .load-more {
  display: block;
  width: 33% !important;
  border: 2px solid #264963;
  margin: 0 auto 1rem;
  background: #ffffff !important;
  color: #0b3778;
  font-weight: normal;
}
.job-filter-block .filters {
  align-content: center;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 992px) {
  .job-filter-block .filters {
    height: initial;
  }
}
.job-filter-block h2 {
  width: 100%;
  margin-top: 1rem !important;
  margin-bottom: 0 !important;
  color: #FFFFFF;
  text-align: center;
}
.job-filter-block .jobs {
  background: #FFFFFF;
}
@media only screen and (max-width: 992px) {
  .job-filter-block .ui.grid > .row.filters > .column {
    padding: 0 35px;
  }
}
.job-filter-block .ui.dropdown {
  width: 100%;
  box-sizing: content-box;
  border: 2px solid #FFFFFF;
  background: transparent;
  border-radius: 26px;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 34px;
  opacity: 1;
  text-align: center;
}
@media only screen and (max-width: 992px) {
  .job-filter-block .ui.dropdown {
    margin: 10px 0;
  }
}
.job-filter-block .ui.dropdown.active.visible {
  border-top: 2px solid #264963;
  border-right: 2px solid #264963;
  border-left: 2px solid #264963;
  background: #FFFFFF;
  border-radius: 13px 13px 0 0;
  color: #264963;
}
.job-filter-block .ui.dropdown.active.visible.upward {
  border-bottom: 2px solid #264963;
  border-radius: 0 0 13px 13px;
}
.job-filter-block .ui.dropdown.active.visible.upward > .menu.visible {
  border-top: 2px solid #264963;
  border-bottom: none;
  border-radius: 13px 13px 0 0;
}
.job-filter-block .ui.dropdown > .menu.visible {
  left: -2px;
  width: inherit;
  min-width: 0;
  border: 2px solid #264963;
  border-top: 0;
  border-radius: 0 0 13px 13px;
  box-shadow: none;
}
.job-filter-block .ui.dropdown > .menu.visible .dropdown-scroller {
  max-height: 400px;
  overflow-y: scroll;
}
.job-filter-block .ui.dropdown > .menu.visible .dropdown-scroller .item {
  position: relative;
  display: block;
  height: auto;
  padding: 0.77777778rem 1.16666667rem !important;
  border: none;
  border-top: none;
  border-top-width: medium;
  box-shadow: none;
  color: #636363;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1em;
  text-align: left;
  text-transform: none;
}
.job-filter-block .ui.dropdown > .menu.visible .dropdown-scroller .item:hover {
  z-index: 13;
  background: rgba(0, 0, 0, 0.05);
  color: #636363;
}
.job-filter-block .ui.dropdown > .menu.visible .text {
  width: 100%;
  font-size: 16px;
  white-space: normal;
}
.job-filter-block .ui.button {
  width: 100%;
  height: 38px;
  padding: 0;
  background: #E20026;
  font-size: 16px;
}
@media only screen and (max-width: 992px) {
  .job-filter-block .ui.button {
    margin: 10px auto;
  }
}
.job-filter-block .no-jobs button {
  display: contents;
  border-bottom: #FFFFFF solid 1px;
  background: transparent;
  color: #FFFFFF;
  cursor: pointer;
  font-family: 'FuturaPT-Bold';
  font-weight: bolder;
}
/* Video Block */
.block.video iframe {
  height: 100%;
}
.block.video .video-inner {
  position: relative;
}
.block.video .video-inner .ui.embed i.icon {
  color: #264963;
  font-family: 'Font Awesome 5 Brands';
  font-size: 150px;
}
@media only screen and (max-width: 992px) {
  .block.video .video-inner .ui.embed i.icon {
    font-size: 45px;
  }
}
.block.video .video-inner .youtube-agreement {
  position: relative;
  padding: 0;
  color: #264963;
}
.block.video .video-inner .youtube-agreement img {
  width: 100%;
}
.block.video .video-inner .youtube-agreement .youtube-text-backdrop {
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  font-weight: normal;
}
.block.video .video-inner .youtube-agreement .youtube-text-backdrop .youtube-text {
  width: 100%;
  max-width: 933px;
  padding-top: 2rem;
}
@media only screen and (max-width: 767px) {
  .block.video .video-inner .youtube-agreement .youtube-text-backdrop .youtube-text {
    padding-top: 0.5rem;
  }
  .block.video .video-inner .youtube-agreement .youtube-text-backdrop .youtube-text h3 {
    margin-bottom: 0;
  }
  .block.video .video-inner .youtube-agreement .youtube-text-backdrop .youtube-text p {
    font-size: 12px;
  }
}
.block.video .video-inner .youtube-agreement .youtube-text-backdrop .fa-youtube {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 150px;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 992px) {
  .block.video .video-inner .youtube-agreement .youtube-text-backdrop .fa-youtube {
    font-size: 45px;
    opacity: 0.5;
  }
}
/* benefits */
.block.benefits {
  background: linear-gradient(to left, #3890d2 0%, #0a2576 100%);
  color: #FFFFFF;
}
.block.benefits .fal,
.block.benefits .fas {
  font-size: 2em;
}
.block.benefits .ui.grid {
  min-height: 645px;
  align-content: center;
}
.block.benefits .ui.grid .row {
  justify-content: center;
  padding-left: 65px;
}
@media only screen and (max-width: 992px) {
  .block.benefits h2 {
    font: 18pt/29pt 'FuturaPT-Bold';
  }
  .block.benefits .ui.grid .row {
    padding: 0 20px;
  }
  .block.benefits .ui.grid .row .column {
    margin: 10px 0;
  }
  .block.benefits .fal {
    font-size: 5em;
  }
  .block.benefits .laptop-house {
    height: 5em !important;
  }
}
.block.benefits .laptop-house {
  height: 2em;
  fill: white;
}
/* Job offers view */
.job-offer-view .introduction {
  bottom: 0;
  padding: 1rem;
}
.job-offer-view .introduction h2 {
  margin-bottom: 1rem;
  font: 2.5rem 'FuturaPT-Bold';
}
.job-offer-view .introduction h3 {
  margin-top: 0;
  color: #E20026;
  font: 1.5rem 'FuturaPT-Bold';
}
.job-offer-view .block-header {
  width: 1351px;
  background: #264963;
}
.job-offer-view .block-header h1.documentFirstHeading {
  max-width: 921px;
  padding: 1rem;
  border: none;
  margin: 0 auto;
  background: #264963;
  color: #FFFFFF;
}
.job-offer-view .ui.button.primary {
  padding: 10px 22px;
  margin-right: 0;
  background: #E20026;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
}
.job-offer-view .row.job-profile .column {
  display: flex;
  align-items: flex-start;
}
.job-offer-view .row.job-profile .column i {
  font-size: 2rem;
}
.job-offer-view .row.job-profile .column p {
  margin-left: 1rem;
}
@media only screen and (max-width: 767px) {
  .job-offer-view .row.job-profile .column {
    margin-bottom: 0.5rem;
  }
  .job-offer-view .row.job-profile .column i {
    min-width: 45px;
    text-align: center;
  }
}
.job-offer-view .block.benefits .ui.grid {
  max-width: 921px !important;
}
.job-offer-view .block.benefits .ui.grid .row {
  justify-content: left;
  padding-left: 1rem;
}
.job-offer-view .block.benefits .ui.grid .row h4 {
  font-size: 1.5rem;
}
.job-offer-view .block.benefits .ui.grid .row i {
  font-size: 2rem;
}
.job-offer-view .block.benefits .ui.grid .row .benefit {
  padding-right: 1rem;
}
.job-offer-view .block.benefits .ui.grid .row .benefit.col-sm-6 {
  width: 50%;
}
@media only screen and (max-width: 767px) {
  .job-offer-view .block.benefits .ui.grid .row .benefit.col-xs-12 {
    width: 100%;
  }
}
.job-offer-view .job-description ul {
  padding-left: 1rem;
}
.job-offer-view .block.hero.contact .block-inner-wrapper {
  min-height: initial;
}
.job-offer-view .block.hero.contact .hero-image-container p {
  margin: 0;
  line-height: 0;
}
.job-offer-view .block.hero.contact .hero-image-container .img-person {
  width: 100%;
  max-width: initial;
  min-height: 100%;
  max-height: none;
  object-fit: cover;
}
.job-offer-view .block.hero.contact .hero-body {
  padding: 0 1rem;
  margin: 0;
}
/* Slider */
.block.aktuelles {
  margin-bottom: 65px;
}
.block.aktuelles h2.slider-title {
  width: 100%;
  margin: 1rem 0;
  text-align: center;
}
.block.aktuelles .slick-list {
  width: 100%;
  margin-right: auto !important;
  margin-left: auto !important;
}
.block.aktuelles .aktuelles-image-wrapper {
  display: flex;
  margin-bottom: 20px;
}
.block.aktuelles .aktuelles-image-wrapper img {
  width: 100%;
  object-fit: contain;
}
@media only screen and (max-width: 767px) {
  .block.aktuelles .aktuelles-image-wrapper {
    margin-bottom: 0px;
  }
}
.block.aktuelles .aktuelles-text-wrapper {
  margin: 0 35px;
}
.block.aktuelles .grid-teaser-item {
  padding: 8px 8px;
}
.block.aktuelles .grid-teaser-item a {
  color: #264963;
}
@media only screen and (max-width: 767px) {
  .block.aktuelles .grid-teaser-item {
    margin: 0;
  }
}
.block.aktuelles .grid-teaser-item .ui.button.fluid.circular {
  border: #264963 2px solid;
  margin-top: 1rem;
  background: #FFFFFF;
  color: #264963;
  font-weight: normal;
}
.block.aktuelles .grid-teaser-item h2 {
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .block.aktuelles .grid-teaser-item h2 {
    min-height: initial;
    margin-top: 1rem;
  }
}
@media only screen and (min-width: 767px) {
  .block.aktuelles .slick-dots {
    display: none !important;
  }
}
.block.aktuelles .slick-dots li.slick-active button::before {
  content: '\f111';
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}
.block.aktuelles .slick-dots li button::before {
  color: #264963;
  content: '\f111';
  font-family: 'Font Awesome 5 Pro';
  font-size: 0.75rem;
  font-weight: 300;
  opacity: 1;
}
.block.aktuelles .slick-arrow {
  display: flex;
  width: 30px;
  height: 60px;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  color: #264963;
  font-size: 2rem;
}
.block.aktuelles .slick-arrow.ui.button {
  z-index: 10;
  top: 35%;
  padding: 0;
  margin: 0;
  border-radius: 0;
}
.block.aktuelles .slick-arrow.ui.button .icon:not(.button) {
  margin: 0;
  opacity: 1;
}
.block.aktuelles .slick-arrow.slick-prev {
  left: 0;
}
.block.aktuelles .slick-arrow.slick-next {
  right: 0;
}
.block.aktuelles .slick-arrow.slick-prev::before,
.block.aktuelles .slick-arrow.slick-next::before {
  display: none;
}
/*Cookie Banner*/
.ui.inverted.page.modals.dimmer {
  padding: 0;
}
@media only screen and (max-width: 767px) {
  .ui.inverted.page.modals.dimmer #question-landing.modal.visible {
    position: static;
    margin-top: 1rem;
    overflow-y: scroll;
  }
  .ui.inverted.page.modals.dimmer #question-landing.modal.visible .actions {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 60px !important;
  }
}
.ui.inverted.page.modals.dimmer #question-landing.modal.visible a {
  text-decoration: underline;
}
.ui.inverted.page.modals.dimmer #question-landing.modal.visible .ui.form {
  margin-top: 1rem;
}
.ui.inverted.page.modals.dimmer #question-landing.modal.visible .ui.button.circular {
  border: #264963 2px solid;
  background: #FFFFFF;
  color: #264963;
  font-weight: normal;
}
.ui.inverted.page.modals.dimmer #question-landing.modal.visible .ui.button.circular.red {
  border: #E20026;
  background: #E20026;
  color: #FFFFFF;
  font-weight: bold;
}
.block.cookies .ui.button.circular {
  border: #264963 2px solid;
  margin-top: 30px;
  background: #FFFFFF;
  color: #264963;
  font-weight: normal;
}
#toolbar .toolbar-content {
  z-index: 1000;
}
.block.meldungen {
  margin-bottom: 65px;
}
.block.meldungen h2.meldungen-title {
  width: 100%;
  margin: 1rem 0;
  color: #264963;
  text-align: center;
}
.meldungen-text-wrapper {
  color: #264963;
}
