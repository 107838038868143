@import 'layout';
@import 'typography';
@import '~@fortawesome/fontawesome-pro/css/all.css';
@import '~@kitconcept/volto-blocks/theme/main';
@import (less) '~slick-carousel/slick/slick.css';
@import (less) '~slick-carousel/slick/slick-theme.css';

@charset "utf-8";

/* Basics */
body {
  background: @white;
  color: @darkBlue;
}

.block > * {
  background: inherit;
}

.block,
.ui.grid {
  margin-top: 0;
  margin-bottom: 0;
}

.view-editview {
  .block {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}

.ui.button.fluid.circular.joblisting {
  height: inherit;
  min-height: 1em;
  padding: 0.61111111em 1.5em 0.61111111em 1.5rem;
  font-size: 1rem;
  line-height: 1em;
}

h1,
h2 {
  font-family: @boldFont;
  text-transform: uppercase;
}

p:empty {
  min-height: 19px;
}

*.color.red {
  color: @red;
}

#main {
  height: fit-content;
}

/* Sidebar */

.sidebar-container {
  color: @black;

  .background-color-chooser {
    .ui.button {
      width: 25px;
      height: 25px;
      padding: 0;
      margin: 0 10px;

      &.white {
        border: 1px @black solid;
        background: @white;
      }

      &.blue {
        background: linear-gradient(90deg, #0a2576 0%, #3890d2 100%);
      }

      &.grey {
        background: linear-gradient(to left, #fff 0%, #b3b3b3 100%);
      }

      &.selected {
        border: 2px @blue solid;
      }
    }
  }
}

/* Header */
.main-header {
  position: sticky;
  z-index: 999;

  @media only screen and (max-width: @tabletBreakpoint) {
    position: fixed;
    z-index: 100;
    width: 100%;
    background: @white;
  }

  .ui.basic.segment.header-wrapper {
    padding: 0;
    font-size: 22px;
    letter-spacing: 0;
    line-height: 29px;

    .ui.container {
      @media only screen and (max-width: @tabletBreakpoint) {
        margin-right: 0 !important;
        margin-left: 0 !important;
      }
    }

    .header {
      height: 75px;
      flex-direction: row;
      align-items: center;

      @media only screen and (min-width: @tabletBreakpoint) {
        padding: 0 18px;
      }
      @media only screen and (max-width: @tabletBreakpoint) {
        height: 48px;
        box-shadow: 0 3px 6px rgba(0, 0, 41, 0.6);
      }

      .logo-nav-wrapper {
        @media only screen and (max-width: @tabletBreakpoint) {
          align-items: center;
          justify-content: space-between;
          margin-right: 20px;
          margin-left: 20px;
        }

        .logo img {
          @media only screen and (max-width: @tabletBreakpoint) {
            height: 22px;
          }
        }

        .hamburger-button {
          display: none;
          border: none;
          background: transparent;
          cursor: pointer;

          @media only screen and (max-width: @tabletBreakpoint) {
            display: block;
          }
        }
      }

      .language-selector {
        color: @darkBlue;

        @media only screen and (max-width: @tabletBreakpoint) {
          display: none;
        }

        a {
          color: @darkBlue;

          &.selected {
            text-decoration: underline;
          }
        }
      }

      .ui.button.primary {
        padding: 10px 22px;
        margin-right: 0;
        margin-left: 36px;
        background: @red;
        font-size: 18px;
        font-weight: normal;
        line-height: 22px;

        @media only screen and (max-width: @computerBreakpoint) {
          display: none;
        }
      }
    }
  }

  .header-placeholder {
    display: none;
    height: 39px;

    &.visible {
      display: block;
    }
  }
}

.ui.basic.segment.sub-navigation {
  padding: 0;
  margin-top: 0;
  background: @lighterGrey;
  box-shadow: inset 0px 2px 5px -3px rgba(0, 0, 0, 0.75);

  &.static {
    position: sticky !important;
    z-index: 50;
    top: 0;
    width: 100%;
  }

  @media only screen and (max-width: @computerBreakpoint) {
    .ui.container {
      margin: 0 !important;
    }
  }

  .ui.secondary.pointing.menu {
    position: relative;
    min-height: initial;
    padding: 0 1rem;
    margin: 0;

    .nav-item-wrapper {
      z-index: 999;
      display: flex;
      padding: 8px 0;

      &:hover {
        .submenu-wrapper {
          display: block;
        }
      }

      .submenu-wrapper {
        position: absolute;
        z-index: 999;
        top: 39px;
        left: 0;
        display: none;
        width: 100%;
        padding-left: 0;
        margin-top: 0;
        background: @white;

        &.active {
          display: block;
        }

        ul {
          display: flex;
          padding-left: 1rem;
          padding-left: 0;
          margin: 0;
          list-style: none;

          li {
            background: @white;

            a {
              padding: 1rem;
              margin: 0;

              &:hover {
                background: @lighterGrey;
              }
            }
          }
        }
      }
    }

    .item {
      padding: 0;
      border-bottom: none;
      color: @darkBlue;
      font-size: 16px;
      line-height: 23px;
      text-transform: none;

      &.active {
        border-bottom: none;
        font-family: @boldFont;
      }
    }
  }
}

/* Mobile Navigation */
.mobile.navigation {
  display: none;

  &.open {
    display: block;
  }

  .ui.pointing.menu {
    position: fixed;
    z-index: 101;
    top: 0;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    padding: 40px 36px;
    background: linear-gradient(#3890d2 0%, #0a2576 100%);
    color: @white;
    overflow-y: scroll;

    .nav-item-wrapper {
      align-items: center;

      & > div {
        display: flex;
      }

      &.last {
        padding-bottom: 50px;
      }

      button {
        border: none;
        background: transparent;
        color: @white;

        i {
          font-size: 1.5rem;
        }
      }

      &.submenu-open {
        i {
          transform: rotate(90deg);
        }
      }

      .submenu-wrapper {
        // position: absolute;
        z-index: 100;
        // top: 180px;
        left: 0;
        display: none;
        width: 100vw;

        button {
          padding-left: 20px;
          text-transform: uppercase;

          i {
            position: relative;
            top: 4px;
            margin-right: 10px;
          }
        }

        &.open {
          display: block;
        }

        ul {
          padding-left: 1rem;
          margin-top: 1rem;
          list-style: none;

          li {
            padding: 12px 0;

            .item {
              margin: 0;
            }
          }
        }
      }
    }

    a {
      border: none;
      color: @white;
    }

    .tools-wrapper.mobile.only {
      display: flex;
      justify-content: flex-end;

      .language-selector {
        display: block;
        margin-right: 25px;
        color: @white;
        font-size: 20px;
        line-height: 25px;

        a.selected {
          text-decoration: underline;
        }
      }

      svg {
        cursor: pointer;
      }
    }

    h2 {
      margin-bottom: 50px;
      font-family: @boldFont;
      font-size: 25px;
      line-height: 31px;
      text-transform: uppercase;
    }

    a.item {
      padding: 0;
      margin: 12px 0;
      text-transform: uppercase;

      &.active {
        color: @white;
        font-family: @boldFont;
      }
    }
  }
}

/* Footer */
.ui.segment.footer .container {
  display: flex;
  height: 185px;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-around;

  @media only screen and (max-width: @computerBreakpoint) {
    height: initial;
    padding-bottom: 50px;
  }

  .ui.list {
    display: flex;
    align-content: center;
    justify-content: space-around;

    @media only screen and (max-width: @computerBreakpoint) {
      justify-content: center;
      margin-top: 25px;
    }

    a.item {
      padding: 0;
      color: @darkBlue;
      font-size: 25px;
      line-height: 36px;

      svg {
        height: 30px;

        @media only screen and (max-width: @computerBreakpoint) {
          height: 25px;
        }
      }
    }

    &.footer-text a {
      @media only screen and (max-width: @computerBreakpoint) {
        font-size: 18px;
      }
    }

    &.footer-icons,
    &.footer-text {
      width: 40%;

      @media only screen and (max-width: @computerBreakpoint) {
        width: 100%;
        padding: 0 35px;
      }
    }

    @media only screen and (max-width: @computerBreakpoint) {
      &.footer-text {
        justify-content: space-around;
      }
    }

    &.footer-logo {
      width: 20%;

      @media only screen and (max-width: @computerBreakpoint) {
        width: 100%;
      }

      .ui.image {
        @media only screen and (max-width: @computerBreakpoint) {
          position: initial;
          height: 29px;
        }
      }
    }
  }
}

/* Content */
.documentFirstHeading {
  &::before {
    display: none;
  }
}

.ui.basic.segment.content-area {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;

  @media only screen and (max-width: @tabletBreakpoint) {
    margin-top: 48px;
  }

  @media only screen and (min-width: @tabletBreakpoint) {
    &.third-layer-page {
      margin-top: 60px;
    }
  }
}

/* Hero Block */

.block.hero {
  background: @white;

  h2 {
    font: 25px/31px @boldFont;
  }

  .block-inner-wrapper {
    min-height: 675px;
    @media only screen and (max-width: @largeMonitorBreakpoint) {
      min-height: 50vw;
    }

    & > h2,
    & > h1 {
      display: none;
      text-transform: uppercase;
    }
  }

  &.title {
    h1 {
      font: 60px/65px @boldFont;
    }

    .block-inner-wrapper {
      overflow-y: hidden;
    }
  }

  &.blue {
    background: linear-gradient(to left, #3890d2 0%, #0a2576 100%);
    color: @white;
  }

  &.grey {
    background: linear-gradient(to left, #fff 0%, #b3b3b3 100%);
  }

  .hero-image-container {
    width: 50%;

    &.icon {
      display: flex;
      align-items: center;
      justify-content: center;

      .hero-image {
        max-width: 33%;
        max-height: 33%;
        object-fit: scale-down;
      }
    }

    .hero-image {
      overflow: visible;
      width: 100%;
      max-width: initial;
      min-height: 100%;
      max-height: none;
      object-fit: cover;

      .ui.message {
        margin-bottom: 0;
      }

      center {
        .toolbar-inner {
          width: 100%;
          min-width: inherit;
        }
      }
    }
  }

  .dropzone {
    height: 100%;

    .ui.message {
      height: 100%;
    }
  }

  .hero-body {
    overflow: visible;
    width: 50%;
    max-width: 450px;
    max-height: none;
    align-self: center;
    padding: 0;
    margin: 0 auto;
    background: transparent;

    p {
      max-height: none;
    }

    ul {
      padding-left: 1rem;

      li {
        &::marker {
          color: @red;
        }
      }
    }

    .ui.button.fluid.circular {
      border: @darkBlue 2px solid;
      margin-top: 30px;
      background: @white;
      color: @darkBlue;
      font-weight: normal;

      &.primary {
        border-color: @red;
        background: @red;
        color: @white;
      }
    }
  }

  @media only screen and (max-width: @computerBreakpoint) {
    margin-bottom: 0;

    .block-inner-wrapper.full-width {
      display: flex;
      height: 100%;
      flex-wrap: wrap;
      padding: 1rem 35px;

      & > h2 {
        display: inline;
        margin-bottom: 0;
        font: 18pt/29pt @boldFont;
      }

      .hero-image-container {
        width: 100%;
        height: 100%;

        img.hero-image {
          min-height: 0;
          object-fit: contain;
          object-position: left;
        }
      }

      .hero-body {
        & > h2 {
          // display: none;
        }

        width: 100%;
        max-width: none;
        height: 100%;
        flex: auto;
        padding-top: 1rem;
      }
    }

    &.title {
      position: relative;
      height: calc(100vh - 114px);
      min-height: 650px;
      background: none;

      .block-inner-wrapper.full-width {
        min-height: 0;
        padding: 0;
        color: @white;

        h2 {
          display: none;
        }

        .hero-image-container {
          position: absolute;
          z-index: -1;
          min-height: 100%;

          .hero-image {
            position: relative;
            bottom: 0;
            max-width: none;
            min-height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        .hero-body {
          z-index: 10;
          bottom: 0;
          display: flex;
          overflow: hidden;
          height: 100%;
          flex-direction: column;
          justify-content: flex-end;
          padding: 20px 35px;
          background: linear-gradient(#6e6e6e00 0%, #071334 100%);

          a.ui.button.primary {
            font-size: 16pt;
          }

          h1 {
            font: 30pt/43pt @boldFont;
          }
        }
      }
    }
  }

  @media only screen and (max-width: @largestMobileScreen) {
    .hero-image-container.icon {
      margin: 20px 0 20px 0;
    }
  }

  @media only screen and (max-width: @tabletBreakpoint) {
    &.title {
      height: calc(100vh - 48px);
    }
  }

  /* Contact */

  &.contact {
    background: linear-gradient(#fff 0%, #b3b3b3 100%);

    @media only screen and (max-width: @computerBreakpoint) {
      .hero-body {
        max-width: 250px;
      }
    }

    a {
      color: @darkBlue;
      text-decoration: underline;
    }

    .hero-body > * {
      margin: 21px auto;
    }

    &.static {
      .block-inner-wrapper {
        min-height: initial;

        .hero-image-container {
          .hero-image {
            object-fit: contain;
          }
        }
      }
    }
  }

  /* Job Offers */
  &.job-offers {
    &.blue {
      .job-offer-unit a {
        color: @white;
      }
    }

    h2 {
      font-family: @boldFont;
    }

    ul {
      padding-left: 0;
      list-style: none;
    }

    .job-offer-unit {
      width: 100%;
      border-bottom: 1px solid @darkGrey;
      margin-bottom: 1rem;
      line-height: 1rem;

      a {
        color: @darkBlue;
        text-decoration: underline;
      }

      p {
        color: @darkGrey;
      }
    }

    @media only screen and (max-width: @computerBreakpoint) {
      .hero-body > p {
        font-size: 16pt;
        line-height: 24pt;
        text-transform: uppercase;
      }

      .job-offer-unit {
        a {
          font-size: 16pt;
          line-height: 24pt;
          text-transform: uppercase;
        }
      }
    }
  }

  /* Double Text*/
  &.double-text {
    .block-inner-wrapper {
      min-height: 300px;
    }

    .hero-body {
      align-self: flex-start;
      margin-top: 2rem;
    }
  }
}

/* Job Filter */

.job-filter-block {
  padding: 0 1rem;
  color: @white;

  .ui.grid.full-width {
    background: linear-gradient(to left, #3890d2 0%, #0a2576 100%);
  }

  .job {
    display: flex;
    min-height: 332px;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;
    margin-bottom: 1rem;
    background: linear-gradient(180deg, rgb(17, 36, 114), rgb(24, 37, 83) 100%);
    text-align: left;

    h2 {
      color: white;
      font-size: 1.2rem;
      font-weight: bold;
    }

    p {
      color: white;
      font-size: 1rem;
    }

    .ui.button {
      display: block;
      width: 200px;
      margin: 0 auto;
      color: white;
      font-weight: 700;
      line-height: 38px;
    }
  }

  .load-more {
    display: block;
    width: 33% !important;
    border: 2px solid @darkBlue;
    margin: 0 auto 1rem;
    background: #ffffff !important;
    color: #0b3778;
    font-weight: normal;
  }

  .filters {
    align-content: center;
    margin-bottom: 1rem;

    @media only screen and (max-width: @computerBreakpoint) {
      height: initial;
    }
  }

  h2 {
    width: 100%;
    margin-top: 1rem !important;
    margin-bottom: 0 !important;

    color: @white;
    text-align: center;
  }

  .jobs {
    background: @white;
  }

  .ui.grid > .row.filters > .column {
    @media only screen and (max-width: @computerBreakpoint) {
      padding: 0 35px;
    }
  }

  .ui.dropdown {
    width: 100%;
    box-sizing: content-box;
    border: 2px solid @white;
    background: transparent;
    border-radius: 26px;
    color: @white;
    font-size: 16px;
    line-height: 34px;
    opacity: 1;
    text-align: center;

    @media only screen and (max-width: @computerBreakpoint) {
      margin: 10px 0;
    }

    &.active.visible {
      border-top: 2px solid @darkBlue;
      border-right: 2px solid @darkBlue;
      border-left: 2px solid @darkBlue;
      background: @white;
      border-radius: 13px 13px 0 0;
      color: @darkBlue;

      &.upward {
        border-bottom: 2px solid @darkBlue;
        border-radius: 0 0 13px 13px;

        & > .menu.visible {
          border-top: 2px solid @darkBlue;
          border-bottom: none;
          border-radius: 13px 13px 0 0;
        }
      }
    }

    & > .menu.visible {
      left: -2px;
      width: inherit;
      min-width: 0;
      border: 2px solid @darkBlue;
      border-top: 0;
      border-radius: 0 0 13px 13px;
      box-shadow: none;

      .dropdown-scroller {
        max-height: 400px;
        overflow-y: scroll;

        .item {
          position: relative;
          display: block;
          height: auto;
          padding: 0.77777778rem 1.16666667rem !important;
          border: none;
          border-top: none;
          border-top-width: medium;
          box-shadow: none;
          color: #636363;
          cursor: pointer;
          font-size: 1rem;
          font-weight: 300;
          line-height: 1em;
          text-align: left;
          text-transform: none;

          &:hover {
            z-index: 13;
            background: rgba(0, 0, 0, 0.05);
            color: #636363;
          }
        }
      }

      .text {
        width: 100%;
        font-size: 16px;
        white-space: normal;
      }
    }
  }

  .ui.button {
    width: 100%;
    height: 38px;
    padding: 0;
    background: @red;
    font-size: 16px;
    @media only screen and (max-width: @computerBreakpoint) {
      margin: 10px auto;
    }
  }

  .no-jobs button {
    display: contents;
    border-bottom: @white solid 1px;
    background: transparent;
    color: @white;
    cursor: pointer;
    font-family: @boldFont;
    font-weight: bolder;
  }
}

/* Video Block */
.block.video {
  iframe {
    height: 100%;
  }

  .video-inner {
    position: relative;

    .ui.embed i.icon {
      color: @darkBlue;
      font-family: 'Font Awesome 5 Brands';
      font-size: 150px;

      @media only screen and (max-width: @computerBreakpoint) {
        font-size: 45px;
      }
    }

    .youtube-agreement {
      position: relative;
      padding: 0;
      color: @darkBlue;

      img {
        width: 100%;
      }

      .youtube-text-backdrop {
        position: absolute;
        top: 0;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        background: rgba(255, 255, 255, 0.8);
        font-weight: normal;

        .youtube-text {
          width: 100%;
          max-width: 933px;
          padding-top: 2rem;
          @media only screen and (max-width: @largestMobileScreen) {
            padding-top: 0.5rem;

            h3 {
              margin-bottom: 0;
            }

            p {
              font-size: 12px;
            }
          }
        }

        .fa-youtube {
          position: absolute;
          top: 50%;
          left: 50%;
          font-size: 150px;
          transform: translate(-50%, -50%);
          @media only screen and (max-width: @computerBreakpoint) {
            font-size: 45px;
            opacity: 0.5;
          }
        }
      }
    }
  }
}

/* benefits */
.block.benefits {
  background: linear-gradient(to left, #3890d2 0%, #0a2576 100%);
  color: @white;

  .fal,
  .fas {
    font-size: 2em;
  }

  .ui.grid {
    min-height: 645px;
    align-content: center;

    .row {
      justify-content: center;
      padding-left: 65px;
    }
  }

  @media only screen and (max-width: @computerBreakpoint) {
    h2 {
      font: 18pt/29pt @boldFont;
    }

    .ui.grid .row {
      padding: 0 20px;

      .column {
        margin: 10px 0;
      }
    }

    .fal {
      font-size: 5em;
    }

    .laptop-house {
      height: 5em !important;
    }
  }

  .laptop-house {
    height: 2em;
    fill: white;
  }
}

/* Job offers view */
.job-offer-view {
  .introduction {
    bottom: 0;
    padding: 1rem;

    h2 {
      margin-bottom: 1rem;
      font: 2.5rem @boldFont;
    }

    h3 {
      margin-top: 0;
      color: @red;
      font: 1.5rem @boldFont;
    }
  }

  .block-header {
    width: 1351px;
    background: @darkBlue;

    h1.documentFirstHeading {
      max-width: 921px;
      padding: 1rem;
      border: none;
      margin: 0 auto;
      background: @darkBlue;
      color: @white;
    }
  }

  .ui.button.primary {
    padding: 10px 22px;
    margin-right: 0;
    background: @red;
    font-size: 18px;
    font-weight: normal;
    line-height: 22px;
  }

  .row.job-profile {
    .column {
      display: flex;
      align-items: flex-start;

      i {
        font-size: 2rem;
      }

      p {
        margin-left: 1rem;
      }

      @media only screen and (max-width: @largestMobileScreen) {
        margin-bottom: 0.5rem;

        i {
          min-width: 45px;
          text-align: center;
        }
      }
    }
  }

  .block.benefits {
    .ui.grid {
      max-width: 921px !important;

      .row {
        justify-content: left;
        padding-left: 1rem;

        h4 {
          font-size: 1.5rem;
        }

        i {
          font-size: 2rem;
        }

        .benefit {
          padding-right: 1rem;

          &.col-sm-6 {
            width: 50%;
          }

          &.col-xs-12 {
            @media only screen and (max-width: @largestMobileScreen) {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .job-description {
    ul {
      padding-left: 1rem;
    }
  }

  .block.hero.contact {
    .block-inner-wrapper {
      min-height: initial;
    }

    .hero-image-container {
      p {
        margin: 0;
        line-height: 0;
      }

      .img-person {
        width: 100%;
        max-width: initial;
        min-height: 100%;
        max-height: none;
        object-fit: cover;
      }
    }

    .hero-body {
      padding: 0 1rem;
      margin: 0;
    }
  }
}

/* Slider */
.block.aktuelles {
  margin-bottom: 65px;

  h2.slider-title {
    width: 100%;
    margin: 1rem 0;
    text-align: center;
  }

  .slick-list {
    width: 100%;
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .aktuelles-image-wrapper {
    display: flex;
    margin-bottom: 20px;

    img {
      width: 100%;
      object-fit: contain;
    }

    @media only screen and (max-width: @largestMobileScreen) {
      margin-bottom: 0px;
    }
  }

  .aktuelles-text-wrapper {
    margin: 0 35px;
  }

  .grid-teaser-item {
    padding: 8px 8px;

    a {
      color: @darkBlue;
    }

    @media only screen and (max-width: @largestMobileScreen) {
      margin: 0;
    }

    .ui.button.fluid.circular {
      border: @darkBlue 2px solid;
      margin-top: 1rem;
      background: @white;
      color: @darkBlue;
      font-weight: normal;
    }

    h2 {
      text-align: center;

      @media only screen and (max-width: @largestMobileScreen) {
        min-height: initial;
        margin-top: 1rem;
      }
    }
  }

  .slick-dots {
    @media only screen and (min-width: @largestMobileScreen) {
      display: none !important;
    }

    li {
      &.slick-active {
        button {
          &::before {
            content: '\f111';
            font-family: 'Font Awesome 5 Pro';
            font-weight: 900;
          }
        }
      }

      button {
        &::before {
          color: @darkBlue;
          content: '\f111';
          font-family: 'Font Awesome 5 Pro';
          font-size: 0.75rem;
          font-weight: 300;
          opacity: 1;
        }
      }
    }
  }

  .slick-arrow {
    display: flex;
    width: 30px;
    height: 60px;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    color: @darkBlue;
    font-size: 2rem;

    &.ui.button {
      z-index: 10;
      top: 35%;
      padding: 0;
      margin: 0;
      border-radius: 0;

      .icon:not(.button) {
        margin: 0;
        opacity: 1;
      }
    }

    &.slick-prev {
      left: 0;
    }

    &.slick-next {
      right: 0;
    }

    &.slick-prev::before,
    &.slick-next::before {
      display: none;
    }
  }
}

/*Cookie Banner*/
.ui.inverted.page.modals.dimmer {
  padding: 0;

  #question-landing.modal.visible {
    @media only screen and (max-width: @largestMobileScreen) {
      position: static;
      margin-top: 1rem;
      overflow-y: scroll;

      .actions {
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 60px !important;
      }
    }

    a {
      text-decoration: underline;
    }

    .ui.form {
      margin-top: 1rem;
    }

    .ui.button.circular {
      border: @darkBlue 2px solid;
      background: @white;
      color: @darkBlue;
      font-weight: normal;

      &.red {
        border: @red;
        background: @red;
        color: @white;
        font-weight: bold;
      }
    }
  }
}

//Cookie Block

.block.cookies {
  .ui.button.circular {
    border: @darkBlue 2px solid;
    margin-top: 30px;
    background: @white;
    color: @darkBlue;
    font-weight: normal;
  }
}

//Toolbar

#toolbar .toolbar-content {
  z-index: 1000;
}

//meldungen block
.block.meldungen {
  margin-bottom: 65px;

  h2.meldungen-title {
    width: 100%;
    margin: 1rem 0;
    color: @darkBlue;
    text-align: center;
  }
}

.meldungen-text-wrapper {
  color: @darkBlue;
}
