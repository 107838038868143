@toolbarWidth: 80px;
@sidebarWidth: 375px;
@collapsedWidth: 20px;

@import '~@plone/volto/../theme/themes/default/elements/container.variables';

.containerBehavior(@offset) {
  @media only screen and (max-width: @largestMobileScreen + @offset) {
    .block.aktuelles .slick-list {
      width: @mobileWidth !important;
      margin-right: @mobileGutter !important;
      margin-left: @mobileGutter !important;
    }
  }

  @media only screen and (min-width: @tabletBreakpoint + @offset) and (max-width: @largestTabletScreen + @offset) {
    .block.aktuelles .slick-list {
      width: @tabletWidth !important;
      margin-right: @tabletGutter !important;
      margin-left: @tabletGutter !important;
    }
  }

  @media only screen and (min-width: @computerBreakpoint + @offset) and (max-width: @largestSmallMonitor + @offset) {
    .block.aktuelles .slick-list {
      width: @computerWidth !important;
      margin-right: @computerGutter !important;
      margin-left: @computerGutter !important;
    }
  }

  @media only screen and (min-width: @largeMonitorBreakpoint + @offset) {
    .block.aktuelles .slick-list {
      // width: @largeMonitorWidth !important;
      margin-right: @largeMonitorGutter !important;
      margin-left: @largeMonitorGutter !important;
    }
  }
}

.addContentWidthStyling(@offset) {
  @media only screen and (max-width: @largestSmallMonitor + @offset) {
    /* Main frame fluid up to 1440 */
    .header-wrapper .ui.container {
      width: 100% !important;
    }
  }

  @media only screen and (min-width: @largeMonitorBreakpoint + @offset) {
    #page-document.ui.container {
      width: @computerWidth !important;
    }
  }

  /* Disable full-width at 1440 */
  @media only screen and (min-width: @largeMonitorBreakpoint + @offset) {
    .full-width {
      position: static;
      width: initial !important;
    }

    // Only for fixing the hack + flex + absolute element inside
    .fix-width-issue {
      width: @largeMonitorWidth;
    }

    // Support for full-width blocks containers
    .block.hero,
    .job-filter-block,
    .benefits,
    .block.maps.align.full,
    .block.video.align.full,
    .block.header-wrapper,
    .block.aktuelles,
    .block.meldungen,
    .block.image {
      display: flex;
      width: 100% !important;
      justify-content: center;

      .full-width {
        width: @largeMonitorWidth !important;
      }
    }
  }
}

body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) {
  .addContentWidthStyling(0);
}

body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) {
  .addContentWidthStyling(@toolbarWidth);
}

body.has-toolbar.has-sidebar {
  .addContentWidthStyling(@toolbarWidth + @sidebarWidth);
}

body.has-toolbar.has-sidebar-collapsed {
  .addContentWidthStyling(@toolbarWidth + @collapsedWidth);
}

body.has-toolbar-collapsed:not(.has-sidebar):not(.has-sidebar-collapsed) {
  .addContentWidthStyling(@collapsedWidth);
}

body.has-toolbar-collapsed.has-sidebar {
  .addContentWidthStyling(@collapsedWidth + @sidebarWidth);
}

body.has-toolbar-collapsed.has-sidebar-collapsed {
  .addContentWidthStyling(@collapsedWidth + @collapsedWidth);
}
